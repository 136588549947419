import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit, OnDestroy {
  @Input() small = false;
  @Input() noTextMobile = false;
  @Input() dismissId: string;
  @Input() canDismiss = true;
  @Input() trigger: any;
  @Input() triggerEvent = 'click';
  triggerElement: HTMLElement | null;
  constructor(private cd: ChangeDetectorRef) { }


  get isDismissed(): boolean {
    return localStorage.getItem(this.dismissId) === 'false';
  }

  dismiss(): void {
    localStorage.setItem(this.dismissId, 'false');
  }

  ngOnInit(): void {
    this.triggerElement = this.getNativeElement(this.trigger);

    if (this.triggerElement && this.canDismiss) {
      this.triggerElement.addEventListener(this.triggerEvent, this.onEvent);
    }
  }

  ngOnDestroy(): void {
    if (this.triggerElement) {
      this.triggerElement.removeEventListener(this.triggerEvent, this.onEvent);
    }
  }

  onEvent = () => {
    this.dismiss();
    this.cd.detectChanges();
  };


  getNativeElement(trigger: any): HTMLElement | null {
    if (trigger instanceof ElementRef) {
      return trigger.nativeElement;
    }

    if (trigger && trigger._elementRef instanceof ElementRef) {
      return trigger._elementRef.nativeElement;
    }

    if (trigger instanceof HTMLElement) {
      return trigger;
    }

    return null;
  }

}
