import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SyncState } from 'src/app/pages/payroll/store/model';
import { environment } from 'src/environments/environment';
import { HttpRequestOptions } from '../api.service';

export interface IntegrationsApiResponse<T> {
  data?: T;
  message: string;
  statusCode: number;
}

export interface Integration {
  appName: string;
  displayName: string;
  connectionMethod: string;
  oauthUrl: string;
  connected: boolean;
}

export interface IntegrationToken {
  appName: string;
  companyId: string;
  deploymentId: string;
  accessToken: string;
  refreshToken: string;
  expiryAccessToken: DateTime;
  expiryRefreshToken: DateTime;
  authorizationCode: string;
}




@Injectable({ providedIn: 'root' })
export class IntegrationsService {
  private readonly integrationsApiUrl = environment.integrationsApiUrl;

  constructor(
    private httpClient: HttpClient,
  ) { }

  public getIntegrations(options: HttpRequestOptions = {}) {
    return lastValueFrom(this.httpClient.get<IntegrationsApiResponse<Integration[]>>(`${this.integrationsApiUrl}/v1/integrations`, options)
      .pipe(map(response => response.data)));
  }

  public getToken(options: HttpRequestOptions = {}): Observable<IntegrationToken> {
    return this.httpClient.get<IntegrationsApiResponse<IntegrationToken>>(`${this.integrationsApiUrl}/v1/access-token`, options)
      .pipe(map(response => response.data));
  }

  public deleteToken(options: HttpRequestOptions = {}): Observable<string> {
    return this.httpClient.delete<IntegrationsApiResponse<string>>(`${this.integrationsApiUrl}/v1/delete-token`, options)
      .pipe(map(response => response.data));
  }

  public postToken(body, options: HttpRequestOptions = {}): Observable<IntegrationsApiResponse<string>> {
    return this.httpClient.post<IntegrationsApiResponse<string>>(`${this.integrationsApiUrl}/v1/token`, body, options);
  }

  public syncDataToDeel(body, options: HttpRequestOptions = {}): Observable<IntegrationsApiResponse<boolean>> {
    return this.httpClient.post<IntegrationsApiResponse<any>>(`${this.integrationsApiUrl}/v1/deel/sync/users`, body, options)
      .pipe(map(response => response.data));
  }

  public getDeelSyncStatus(options: HttpRequestOptions = {}): Observable<SyncState> {
    return this.httpClient.get<IntegrationsApiResponse<SyncState>>(`${this.integrationsApiUrl}/v1/deel/sync/last-batch`, options)
      .pipe(map(response => response.data));
  }



}
