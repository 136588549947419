import { isAdmin, isFeatureFlagOn, isManager, isTaskBased, MenuBadge, MenuCondition } from 'src/app/common/menu-def.provider';

export type ExperimentalReportType = 'keyboard-mouse-activity'
  | 'suspicious-activity'
  | 'integration-usage'
  | 'typing-speed'
  | 'unusual-activity'
  | 'unusual-activity-v2'
  | 'vois-timeuse'
  | 'vois-audit-log'
  | 'zendesk-integration'
  | 'leadcare-suspicious-activity'
  | 'firstpage-billable-nonbillable-hours'
  | 'firstpage-utilization'
  | 'firstpage-folders-tasks'
  | 'firstpage-available-vs-tracked-hours'
  | 'firstpage-custom-dashboard'
  | 'supportninja-audit-log'
  | 'hellorache-unusual-activity'
  | 'pineapple-unusual-activity'
  | 'temark-unusual-activity'
  | 'hellorache-audit-log'
  | 'pineapple-audit-log'
  | 'temark-audit-log'
  | 'quit-prediction'
  | 'supportninja-work-schedules-productivity'
  | 'supportninja-reasons-for-archive'
  | 'virtahealth-spark-activity'
  | 'tools-utilization'
  | 'hard-rock-casino-wfh-v-wfo'
  | 'blue-cross-nc-activity';

export interface ExperimentalReportDef {
  id: ExperimentalReportType;
  reportUrl: string;
  condition?: MenuCondition;
  filters?: {
    user?: boolean;
    timezone?: FilterState;
    dateRange?: boolean;
  };
  container?: {
    className?: string;
  };
  wrapperClassName?: string;
  badge?: MenuBadge;
}

export interface FilterState {
  enabled: boolean;
  readonly?: boolean;
}

export const reportDefs: ExperimentalReportDef[] = [
  {
    id: 'keyboard-mouse-activity',
    reportUrl: 'https://datastudio.google.com/embed/reporting/aab6974a-883d-4244-8812-1cd028d2b636/page/4kk8C',
    filters: {
      user: true,
      dateRange: true,
    },
  },
  {
    id: 'suspicious-activity',
    reportUrl: 'https://datastudio.google.com/embed/reporting/d7ee612a-48e8-44ec-bc29-1b56e78888af/page/49TpC',
    filters: {
      user: true,
      dateRange: true,
    },
  },
  {
    id: 'integration-usage',
    reportUrl: 'https://datastudio.google.com/embed/reporting/cdf3f138-d68f-42d8-b19c-27f840b7be11/page/hXeoC',
    condition: isTaskBased,
    filters: {
      user: true,
      dateRange: true,
    },
  },
  {
    id: 'typing-speed',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/4f130f18-6fb4-41b4-9de0-20cb06b13e4b/page/tRCKD',
    filters: {
      user: true,
      dateRange: false,
    },
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'unusual-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/e201f148-4fb4-4355-8853-825083affe7d/page/boLpC',
    condition: isAdmin,
    filters: {
      user: true,
      dateRange: true,
    },
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'unusual-activity-v2',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/eb5ec460-d5c4-43e2-ab84-b7b80d7adfb8/page/boLpC',
    condition: isManager,
    filters: {
      user: true,
      dateRange: true,
      timezone: {
        enabled: true,
        readonly: true,
      },
    },
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'vois-timeuse',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/f389e2aa-b882-49f2-b7b7-90242063b2bb/page/p_qjvod5nl2c',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'vois-audit-log',
    reportUrl: 'https://lookerstudio.google.com/embed/u/0/reporting/8a3d7610-eeb7-40e5-a25c-daa87d601843/page/p_nqkukkinyc',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'zendesk-integration',
    condition: isAdmin,
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/b0916e08-3a9b-40cb-928c-1a54a058b282/page/PhTJD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'leadcare-suspicious-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/9ad85b2c-8515-4c31-8268-f8ff4c5eda36/page/boLpC',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'firstpage-custom-dashboard',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/661546ef-48a7-4eaa-8830-3bed8d831022/page/4jiRD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'firstpage-billable-nonbillable-hours',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/5555ff25-c854-41b8-998e-902df56d45d8/page/oDwQD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'firstpage-utilization',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/efb7741e-cbea-45b5-bfc2-f18e36c59ec3/page/oMwQD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'firstpage-folders-tasks',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/88519a86-893a-41eb-8971-306bdc8105cb/page/phvQD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'firstpage-available-vs-tracked-hours',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/e1846185-28d1-494a-9d80-5be3481469d2/page/3VNRD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'supportninja-audit-log',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/1cbe4673-4afa-4ed0-a5c0-cc929e2313c0/page/no70C',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'hellorache-unusual-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/32577309-16dc-40c9-8503-e4fd2db9ff38/page/boLpC',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'pineapple-unusual-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/97f848ee-ca0b-4165-bf1d-d22736819c73/page/boLpC',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'temark-unusual-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/f031e6c1-b2aa-4446-ac21-0d4a7d3272de/page/boLpC',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'hellorache-audit-log',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/2119f364-dc29-4a98-90db-88223fcb72ad/page/no70C',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'pineapple-audit-log',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/7bcfa01c-b476-4b02-b93d-aa287621b768/page/no70C',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'temark-audit-log',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/a6d16c43-4ebb-40c6-a895-17e97e6dcd27/page/no70C',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'supportninja-reasons-for-archive',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/d201fc6f-2330-48d1-ba94-94c4fdd10d15/page/qEBoD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'supportninja-work-schedules-productivity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/b2466b4b-a7c5-46eb-a291-3e4c9e50b8f0/page/8PegD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'virtahealth-spark-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/27c8315b-2abe-4f00-9c1b-3018c9be8915/page/TNctD',
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'tools-utilization',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/ac162c39-7f38-44ab-8bfc-5bbdca027137/page/tWvQD',
    filters: {
      user: true,
      dateRange: true,
    },
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'quit-prediction',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/7c9dcc76-d750-462d-ac83-09797bf1a3e7/page/boLpC',
    filters: {
      user: true,
      dateRange: true,
    },
    condition: isManager,
    wrapperClassName: 'gray-background',
    badge: { text: 'New' },
  },
  {
    id: 'blue-cross-nc-activity',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/956555c0-a482-49aa-a132-8d8a1566abbc/page/FAH7D',
    condition: isManager,
    container: {
      className: 'full-width',
    },
  },
  {
    id: 'hard-rock-casino-wfh-v-wfo',
    reportUrl: 'https://lookerstudio.google.com/embed/reporting/51ece646-cf37-491d-9d9e-0141374caad7/page/rkcZD',
    condition: isManager,
    container: {
      className: 'full-width',
    },
  },
];


export function hasExperimentalReportAccess(reportDef: ExperimentalReportDef): MenuCondition {
  return (company, injector) => {
    return company &&
      (!reportDef.condition || reportDef.condition(company, injector)) &&
      (isFeatureFlagOn(`experimental-${reportDef.id}`)(company, injector)
        || isFeatureFlagOn(`all-experimental-reports`)(company, injector));
  };
}

export const hasAnyExperimentalReportAccess: MenuCondition = (company, injector) =>
  reportDefs.some(x => hasExperimentalReportAccess(x)(company, injector));
