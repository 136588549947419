import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthState } from 'src/app/store/auth/auth.state';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GrowthBookService } from 'src/app/services/growthbook/service';

export interface TokenResult {
  token: string;
  expires: string;
  createdAt: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})

export class HubspotService {
  constructor(
    private api: ApiService,
    private store: Store,
    private growthBook: GrowthBookService,
  ) { }

  /**
   * Create a visitor token in Hubspot API Call
   * @param email
   * @param firstName
   * @param lastName
   */
  fetchCreateVisitorTokenHubspot(email: string, firstName: string, lastName: string) {
    if (!environment.env.TD_APP_HUBSPOT_TOKEN_GENERATE) {
      const error = new Error('Hubspot token endpoint is not defined.');
      Sentry.captureException(error);
      return;
    }

    if (!email || !firstName) {
      const error = new Error('Email or firstName is empty or undefined');
      Sentry.captureException(error);
      return;
    }

    const body = {
      email,
      firstName,
      lastName,
    };

    try {
      return this.api.request<{ data: TokenResult }>('post', environment.env.TD_APP_HUBSPOT_TOKEN_GENERATE, body);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Check if the token is expired
   * @param tokenCreationTime
   */
  isTokenExpired(tokenCreationTime: number) {
    const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    return Date.now() - tokenCreationTime >= twelveHours;
  }

  featureExcludePrivateCloudVisitorApi() {
    return this.growthBook.getFeature('exclude-private-cloud-visitor-api').pipe(
      untilDestroyed(this),
    ).subscribe(
      (feature: boolean) => {
        if (feature) {
          this.sendInfoToHubspotChat();
        }
      },
    );
  }

  private sendInfoToHubspotChat() {
    this.store.select(AuthState.user).pipe(
      filter(user => !!user),
      take(1),
    ).subscribe(user => {
      const email = user.email;
      const firstName = user.name;

      if (!email || !firstName || email.includes('test+automation')) {
        return;
      }

      const cachedToken = localStorage.getItem('hubspotToken');
      const tokenCreationTime = parseInt(localStorage.getItem('hubspotTokenCreationTime') || '0', 10);

      if (cachedToken && !this.isTokenExpired(tokenCreationTime)) {
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: cachedToken,
        };
        this.triggerHubspotConversation();
      } else {
        window.hsConversationsSettings = {
          loadImmediately: false,
        };

        this.fetchCreateVisitorTokenHubspot(email, firstName, '').subscribe(
          (res) => {
            const token = res.data.token;
            localStorage.setItem('hubspotToken', token);
            localStorage.setItem('hubspotTokenCreationTime', Date.now().toString());

            window.hsConversationsSettings = {
              identificationEmail: email,
              identificationToken: token,
            };
            this.triggerHubspotConversation();
          },
          (err) => {
            this.triggerHubspotConversation();
          },
        );
      }
    });
  }

  private triggerHubspotConversation() {
    window.HubSpotConversations?.widget?.load({
      flowId: environment.env.TD_APP_HUBSPOT_FLOW_ID,
    });
  }
}
