import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom, Subscription } from 'rxjs';
import { ManagerState } from 'src/app/store/manager/manager.state';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class EmployeeTrackStep implements SidebarItem {
  id = 'employeeTrack';
  completed = false;
  link = '/invite';

  private readonly store: Store;

  subscription: Subscription;

  constructor(injector: Injector) {
    this.store = injector.get(Store);
  }

  clear() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async start() {
    this.subscription = this.store.select(ManagerState.users).subscribe(async users => {
      let hasTrackedWork;
      const userCount = users?.length || 0;

      if (userCount) {
        hasTrackedWork = users.some(x => x.lastTrack);
      } else {
        hasTrackedWork = false;
      }

      if (hasTrackedWork) {
        await lastValueFrom(this.store.dispatch(new CompleteWelcomeStep(this.id)));
        await lastValueFrom(this.store.dispatch(new CompleteWelcomeStep('addEmployee')));
      }
    });
  }
}
