import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { take } from 'rxjs';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { CompanyService } from 'src/app/services/company/company.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserSettingsChanged } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import {
  CompleteWelcomeStep,
  DismissWelcomeBanner,
  DismissWelcomeStep,
  OnboardingWizardConfig,
  ResetOnboardingWizardConfig, SetGettingStartedScreenExperimentVariant,
  SetOnboardingWizardConfig,
  ToggleSidebarCollapsed
} from './onboarding.actions';

export interface OnboardingStateModel {
  sidebarCollapsed: boolean;
  showWizard: boolean;
  wizardConfig: OnboardingWizardConfig;
  gettingStartedExperimentVariant?: string | undefined;
  trackedSteps?: {
    [key: string]: boolean;
  };
}

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: {
    sidebarCollapsed: false,
    showWizard: false,
    wizardConfig: {},
    gettingStartedExperimentVariant: '',
  },
})
@Injectable()
export class OnboardingState {
  @Selector()
  public static sidebarCollapsed(state: OnboardingStateModel) {
    return state.sidebarCollapsed;
  }

  @Selector()
  public static wizardConfig(state: OnboardingStateModel) {
    return state.wizardConfig;
  }

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private store: Store,
    private segment: SegmentService,
  ) {
  }

  @Action(ToggleSidebarCollapsed)
  toggleSidebarCollapsed({ patchState }: StateContext<OnboardingStateModel>, { collapsed }: ToggleSidebarCollapsed) {
    patchState({ sidebarCollapsed: collapsed });
  }

  @Action(CompleteWelcomeStep)
  CompleteWelcomeStep({ dispatch, getState }: StateContext<OnboardingStateModel>, { stepId }: CompleteWelcomeStep) {
    const company = this.store.selectSnapshot(AuthState.company);
    const role = this.store.selectSnapshot(AuthState.role);
    const state = getState();

    if (role !== 'owner') return;

    if (stepId === AddCreditCardStep.stepId) {
      const { id, companySettings } = company;

      // Update company settings if the step hasn't been completed yet
      if (!companySettings?.custom?.completedStep?.[stepId]) {
        this.companyService.putCompanySettings(id, { [`custom.completedStep.${stepId}`]: true })
          .pipe(take(1))
          .subscribe();
      }
    }

    const progressMap: Record<string, string> = {
      createAcc: 'Created account',
      addEmployee: 'Invite your first employee',
      employeeTrack: 'Get an employee to track time',
      companySettings: 'Review & adjust settings',
      addCreditCard: 'Choose your plan',
      completeStepId: 'completed',
    };

    const progressName = progressMap[stepId];
    const customSettings = company?.userSettings?.custom;
    const trackedSteps = customSettings?.trackedSteps || {};

    // Check if the step is already tracked
    if (trackedSteps[stepId]) return;

    // If it's a new step, fire tracking event if in 'B' variant
    if (state.gettingStartedExperimentVariant === 'B' && stepId !== 'trackedAll') {
      this.segment.trackInAppEvents('Setup Your Account Widget Progress', {
        progress: progressName,
      });
    }

    // Track side bar activity
    this.segment.trackSideBarActivity(stepId);

    // Mark the step as completed
    this.userService.editProperties('me', { [`custom.completedStep.${stepId}`]: true, [`custom.trackedSteps.${stepId}`]: true})
      .pipe(take(1))
      .subscribe();

    // Dispatch action to update user settings
    dispatch(new UserSettingsChanged(null, { completedStep: { [stepId]: true }, trackedSteps: { [stepId]: true } }));
  }


  @Action(DismissWelcomeStep)
  DismissWelcomeStep({ dispatch }: StateContext<OnboardingStateModel>, { stepId }: DismissWelcomeStep) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.segment.trackSideBarActivity(stepId, true);
    this.userService.editProperties('me', { [`custom.dismissedStep.${stepId}`]: true }).subscribe();
    dispatch(new UserSettingsChanged(null, { dismissedStep: { [stepId]: true } }));
  }

  @Action(DismissWelcomeBanner)
  DismissWelcomeBanner({ dispatch }: StateContext<OnboardingStateModel>) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.userService.editProperties('me', { 'custom.welcomeSidebarCompleted': true } as any).subscribe();
    dispatch(new UserSettingsChanged(null, { welcomeSidebarCompleted: true }));
  }

  @Action(SetOnboardingWizardConfig)
  SetOnboardingWizardConfig({ patchState, getState }: StateContext<OnboardingStateModel>, { config }: SetOnboardingWizardConfig) {
    patchState({ wizardConfig: { ...getState().wizardConfig, ...config } });
  }

  @Action(ResetOnboardingWizardConfig)
  ResetOnboardingWizardConfig({ patchState }: StateContext<OnboardingStateModel>) {
    patchState({ wizardConfig: {} });
  }

  @Action(SetGettingStartedScreenExperimentVariant)
  SetGettingStartedScreenExperimentVariant({ dispatch, patchState, getState }: StateContext<OnboardingStateModel>, { variantId }) {
    this.userService.editProperties('me', { 'custom.gettingStartedExperimentVariant': variantId } as any).subscribe();
    dispatch(new UserSettingsChanged(null, { gettingStartedExperimentVariant: variantId }));
    patchState({ gettingStartedExperimentVariant: variantId  });
  }
}
