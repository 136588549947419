import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetector } from 'src/app/services/segment/device-detector';

@UntilDestroy()
@Component({
  selector: 'app-td-task-progress',
  templateUrl: './td-task-progress.component.html',
  styleUrls: ['./td-task-progress.component.scss'],
})
export class TDTaskProgressComponent implements OnInit {
  @Input() completedTasks = 0;
  @Input() totalTasks = 1;
  isMobileSubject$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly deviceDetector: DeviceDetector) {
  }

  get progressWithColors(): { color: string, rotation: number }[] {
    const segments: { color: string, rotation: number }[] = [];
    const anglePerSegment = 360 / this.totalTasks; // Calculate angle per segment

    for (let i = 0; i < this.totalTasks; i++) {
      segments.push({
        color: i < this.completedTasks ? '#27c26c' : '#585A6E1A', // Green for completed, gray for non-completed
        rotation: anglePerSegment * i, // Rotate each segment to its correct position
      });
    }

    return segments;
  }
  get progress(): number[] {
    return Array.from({ length: this.totalTasks }, (_, i) => i < this.completedTasks ? 100 : 0);
  }

  ngOnInit(): void {
    if (this.completedTasks > this.totalTasks) {
      console.error('Completed tasks cannot exceed total tasks');
    }

    this.deviceDetector.isMobileView().pipe((untilDestroyed(this)),
    ).subscribe(isMobile => {
      this.isMobileSubject$.next(isMobile);
    });
  }
}
