<ng-container *ngIf="allowAccess; else loader">
  <div fxLayout="column" class="addons-container">
    <!-- Check if addons exist and are not empty -->
    <div *ngIf="(addOns$ | async) as addOns">
      <ng-container *ngIf="addOns.length > 0; else emptyState">
        <!-- Optional Add-ons Section (Visible only when addons exist) -->
        <div class="optional-addons" fxLayout="column" fxLayoutAlign="center center">
          <h2 class="addons-title">{{ 'billing.addonsPageTitle' | translate }}</h2>
          <p class="addons-description">
            {{ 'billing.addonsPageSubTitle' | translate }}
          </p>
        </div>

        <!-- Add-ons Cards -->
        <mat-card *ngFor="let addOn of addOns" fxLayout="column" class="card">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header">
            <div fxLayout="row" fxLayoutAlign="start center">
              <img [src]="addOn.imgSrc" [alt]="addOn.imgAlt" [width]="addOn.imgWidth">
              <div class="title">{{ addOn.title }}</div>
            </div>

            <button
              (click)="startHubSpotChat($event, addOn.linkname)"
              mat-raised-button
              color="primary"
              class="learn-more-btn"
              fxShow.gt-sm
              fxHide.lt-sm>
              {{ 'billing.addonsCtaButton' | translate | uppercase }}
            </button>
          </div>

          <div fxLayout="row" fxLayoutAlign="flex-start" class="pricing">
            <div class="price">{{ addOn.price }}</div>
            <div class="price-subtitle">{{ addOn.subtitle }}</div>
          </div>

          <p class="description">
            {{ addOn.description }}
          </p>

          <button
            (click)="startHubSpotChat($event, addOn.linkname)"
            mat-raised-button
            color="primary"
            class="learn-more-btn full-width-btn"
            fxShow.lt-sm
            fxHide.gt-xs>
            {{ 'billing.addonsCtaButton' | translate | uppercase }}
          </button>
        </mat-card>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Empty State -->
<ng-template #emptyState>
  <div class="addons-empty-state-container" fxLayout="column" fxLayoutAlign="center center">
    <app-empty-state fxFlex>
      <span title>{{ 'common.noAddonsTitle' | translate }}</span>
      <span description>{{ 'common.noAddonsDescription' | translate }}</span>
    </app-empty-state>
  </div>
</ng-template>

<ng-template #loader>
  <mat-spinner diameter="50" class="absolute-centered"></mat-spinner>
</ng-template>
