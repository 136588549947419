<div class="task-progress-container">
  <span class="typography-body2 progress-text">
    {{ completedTasks }} of {{ totalTasks }} <ng-container *ngIf="(isMobileSubject$ | async) === false">task{{ totalTasks > 1 ? 's' : '' }} complete</ng-container>
  </span>
  <div *ngIf="(isMobileSubject$ | async); else desktopProgressBar" class="palette">
    <ng-container *ngFor="let progress of progressWithColors; let i = index">
      <div
        [ngStyle]="{ '--c': progress.color, transform: 'rotate(' + progress.rotation + 'deg)' }"
        [style.transform]="'rotate(' + progress.rotation + 'deg)'"
        class="color-segment">
      </div>
    </ng-container>
  </div>
  <ng-template #desktopProgressBar>
    <div *ngIf="(isMobileSubject$ | async) === false" class="progress-bars">
      <ng-container *ngFor="let barValue of progress; let i = index">
        <mat-progress-bar [ngClass]="{'rounded-start': i === 0, 'rounded-end': i === progress.length - 1}" [value]="barValue"
                          class="custom-progress-bar"
                          mode="determinate">
        </mat-progress-bar>
      </ng-container>
    </div>
  </ng-template>
</div>
