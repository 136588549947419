<ng-container *ngIf="!isCollapsed">
  <div class="trial-box" fxLayout="column" *ngIf="variant === 'raised'; else outlinedVariant">
    <div class="trial-title">
      {{ 'time.duration.#day' | translate: {count: trialDaysLeft} }} {{ 'sidebar.widget.title' | translate }}
    </div>
    <div class="trial-subtitle">{{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}</div>
    <div class="trial-description">{{ 'sidebar.widget.description' | translate }}</div>
    <button
      *ngIf="(billingMode$ | async) !=='invoicing'"
      [trackClick]="btnClickTrackData"
      mat-stroked-button
      color="primary"
      class="choose-plan-btn"
      (click)="trialUpgrade()">
      <ng-container *ngIf="!hasCard">{{ 'sidebar.widget.button' | translate | uppercase}}</ng-container>
      <ng-container *ngIf="hasCard">{{ 'sidebar.widget.buttonManagePlan' | translate | uppercase}}</ng-container>
    </button>
    <div class="contact-sales-widget" *ngIf="isWidgetExpanded && hasBookACallAccess">
      <hr>
      <div class="trial-description">{{ 'sidebar.contactSalesWidget.description' | translate }} </div>
      <button (click)="contactSales()" mat-stroked-button class="contact-sales-button"><mat-icon>calendar_today</mat-icon>
        <span>{{ 'sidebar.contactSalesWidget.cta' | translate }}</span>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #outlinedVariant>
  <div *ngIf="variant === 'outlined'; else accordionVariant">
    <span class="icon-wrapper">
      <mat-icon class="material-icons-outlined">timer</mat-icon>
    </span>
    <h6 class="typography-body2 bold">
      {{ 'time.duration.#day' | translate: { count: trialDaysLeft } }} {{ 'sidebar.widget.title' | translate }}
      {{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}
    </h6>
    <p class="typography-caption">{{ 'sidebar.widget.description' | translate }}</p>
    <button
      *ngIf="(billingMode$ | async) !=='invoicing'"
      mat-stroked-button color="primary"
      (click)="trialUpgrade()"
      [trackClick]="btnClickTrackData()"
    >
      {{ 'sidebar.widget.button' | translate | uppercase }}
    </button>
  </div>
</ng-template>

<ng-template #accordionVariant>
  <mat-accordion>
    <mat-expansion-panel
      [(expanded)]=" panelOpenState"
      [class.dragging]="isDragging"
      (opened)="toggleOpenPanelState()"
      (closed)="toggleClosePanelState()"
      #expansionPanel>
      <!-- Expansion panel header -->
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h6 class="typography-subtitle1">
            <span [ngClass]="{ highlighted: !panelOpenState} ">
              {{ 'time.duration.#day' | translate: { count: trialDaysLeft } }}
              {{ 'sidebar.widget.title' | translate }}
            </span>
            {{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}
          </h6>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Expansion panel content -->
      <div>
        <p class="typography-caption">{{ 'sidebar.widget.description' | translate }}</p>
        <button
          *ngIf="(billingMode$ | async) !== 'invoicing'"
          [trackClick]="btnClickTrackData()"
          mat-raised-button
          color="primary"
          class="trial-button"
          (click)="trialUpgrade()">
          <ng-container *ngIf="!hasCard">{{ 'sidebar.widget.button' | translate | uppercase }}</ng-container>
          <ng-container *ngIf="hasCard">{{ 'sidebar.widget.buttonManagePlan' | translate | uppercase }}</ng-container>
        </button>
        <div class="contact-sales-widget" *ngIf="isWidgetExpanded && hasBookACallAccess">
          <hr class="line-separator">
          <a (click)="contactSales()" class="book-a-demo-btn">
            <mat-icon>calendar_today</mat-icon>
            <div>{{ 'sidebar.contactSalesWidget.cta' | translate }}</div>
          </a>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
