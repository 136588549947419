import { AfterViewInit, ContentChildren, Directive, QueryList, Renderer2 } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'mat-form-field',
})
export class MatSelectOnlyChildDirective implements AfterViewInit {
  @ContentChildren(MatSelect) selects!: QueryList<MatSelect>;

  constructor(private formField: MatFormField, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const matSelect = this.selects.first;

    if (matSelect) {
      this.update(matSelect);

      matSelect.options.changes.pipe(untilDestroyed(this)).subscribe(() => this.update(matSelect));
      matSelect.valueChange.pipe(untilDestroyed(this)).subscribe(() => this.update(matSelect));
      matSelect.optionSelectionChanges.pipe(untilDestroyed(this)).subscribe(() => this.update(matSelect));
    }
  }

  private update(matSelect: MatSelect) {
    const hasSingleOption = matSelect.options.length === 1;
    const isOptionSelected = matSelect.options.some(option => option.selected);

    if (hasSingleOption && isOptionSelected) {
      this.renderer.addClass(this.formField._elementRef.nativeElement, 'mat-form-field-disabled');
    } else {
      this.renderer.removeClass(this.formField._elementRef.nativeElement, 'mat-form-field-disabled');
    }
  }
}
