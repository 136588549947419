<mat-toolbar>
  <h2 class="user-name" fxFlex>{{ (user$ | async)?.name }}</h2>

  <button mat-icon-button [matMenuTriggerFor]="profileMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #profileMenu="matMenu">
    <a mat-menu-item routerLink="profile-edit">{{ 'header.editProfile' | translate }}</a>
    <button mat-menu-item (click)="logout()">{{ 'header.logout' | translate }}</button>
  </mat-menu>
</mat-toolbar>

<mat-nav-list *ngIf="company$ | async as company">
  <ng-container *ngFor="let item of menuDef.items">
    <ng-container *ngIf="!item.condition || item.condition(company, injector)">
      <ng-container *ngIf="!$any(item).children; else buttonWithChildren">
        <a mat-list-item [routerLink]="$any(item).routerLink" routerLinkActive="active">
          {{ item.name | translate }}
        </a>
      </ng-container>

      <ng-template #buttonWithChildren>
        <mat-expansion-panel>
          <mat-expansion-panel-header expandedHeight="48px">
            {{ item.name | translate }}
          </mat-expansion-panel-header>

          <mat-nav-list>
            <ng-container *ngFor="let sub of $any(item).children">
              <ng-container *ngIf="!sub.condition || sub.condition(company, injector)">
                <ng-container *ngIf="sub.routerLink; else menuLabel">

                  <a mat-list-item [routerLink]="sub.routerLink" routerLinkActive="active" [fragment]="sub.fragment"
                    [routerLinkActiveOptions]="sub.fragment ? customRouterLinkActiveOptions : {'exact': false}">
                    {{ sub.name | translate }}
                    <ng-container *ngIf="sub.badge">
                      <ng-container *ngIf="sub.badge.length; else singleBadge">
                        <ng-container *ngFor="let item of sub.badge">
                          <ng-template
                            *ngTemplateOutlet="badge; context: { icon: item.icon, iconTooltip: item.iconTooltip, text: item.text, condition: item.condition, tooltip: item.tooltip }"></ng-template>
                        </ng-container>
                      </ng-container>
                      <ng-template #singleBadge>
                        <ng-template
                          *ngTemplateOutlet="badge; context: { icon: sub.badge.icon, iconTooltip: sub.badge.iconTooltip, text: sub.badge.text, condition: sub.badge.condition, tooltip: sub.badge.tooltip }"></ng-template>
                      </ng-template>
                    </ng-container>

                    <ng-template #badge let-condition="condition" let-text="text" let-icon="icon"
                      let-iconTooltip="iconTooltip" let-tooltip="tooltip">
                      <div *ngIf="!condition || condition(company, injector)" [matTooltip]="tooltip | translate"
                        class="sidenav-badge">
                        <mat-icon *ngIf="icon" class="badge-icon" [matTooltip]="iconTooltip | translate">{{ icon
                          }}</mat-icon>
                        <div *ngIf="text" class="badge-text">{{ text | translate }}</div>
                      </div>
                    </ng-template>
                  </a>
                </ng-container>
                <ng-template #menuLabel>
                  <div class="menu-label">{{ sub.name | translate }}</div>
                </ng-template>
              </ng-container>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-nav-list>
