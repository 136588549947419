<div
  class="floating-widget"
  [ngClass]="{ 'dragging': isDragging }"
  [ngStyle]="{ 'top.px': position.top, 'left.px': position.left }"
  (mousedown)="onMouseDown($event)"
  (touchstart)="onTouchStart($event)">
  <app-trial-widget
    variant="accordion"
    [isDragging]="drag"
    [isOnFloatingWidget]="true"
    class="accordioned"
    (panelOpened)="onTrialWidgetPanelOpened($event)"
    (panelClosed)="onTrialWidgetPanelClosed()">
  </app-trial-widget>
</div>
