import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ERROR_URL } from 'src/app/app/app.constants';
import { isOwner } from 'src/app/common/menu-def.provider';
import { NewSubscribePlansState } from 'src/app/pages/new-subscribe-plans/store/state';
import { BillingService } from 'src/app/services/billing/billing.service';
import { EndOfTrialService } from 'src/app/services/end-of-trial/end-of-trial.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class PaidProtectedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private segment: SegmentService,
    private store: Store,
    private router: Router,
    private trialer: EndOfTrialService,
    private billingService: BillingService,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate() {
    const checkoutInfo = this.store.selectSnapshot(NewSubscribePlansState.checkoutSuccessInfo);
    const isEndOfTrialExp = checkoutInfo?.isEndOfTrialExp || false;
    const company = this.store.selectSnapshot(AuthState.company);
    const gracePeriod = await lastValueFrom(this.billingService.getGracePeriodDays().pipe(take(1)));
    let trialEndedManually = false;
    try {
      if (company?.subscription?.provider === 'paddle') {
        const billingDetails = await lastValueFrom(this.billingService.getBillingDetails().pipe(take(1)));
        trialEndedManually = billingDetails?.trialEndedManually;
      }
    } catch (e) {
      console.error('Error fetching billing details', e);
    }
    const isFreeSubscription = company.subscription.status === 'free' && !gracePeriod;
    const hasBillingAccess = company?.userSettings?.billingAccess;

    if (isFreeSubscription) this.segment.trackLockedUser();

    if (isFreeSubscription && (isOwner(company) || hasBillingAccess)) {
      let eotUrl = '/end-of-trial';
      if (company.subscription.provider === 'paddle') {
        if (isEndOfTrialExp) return true;
        eotUrl = trialEndedManually ? '/new-billing' : '/new-end-of-trial';
      }
      return this.router.createUrlTree([eotUrl]);
    } else if (isFreeSubscription && !isOwner(company)) {
      return this.router.createUrlTree([this.errorUrl, 'insufficientPlan']);
    }
    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
