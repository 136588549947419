import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, from, iif, lastValueFrom, Observable, of, ReplaySubject, throwError, zip } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, mergeMap, scan, shareReplay, startWith, switchMap, takeLast, tap } from 'rxjs/operators';

type PagingObject = {
  cur: number;
  next?: number;
  limit: number;
  nItems?: number;
  totalCount: number;
};

export interface PagedApiResult<T = any> {
  data: T[];
  paging?: PagingObject;
  datePaging?: PagingObject;
}

export interface PagedResultMetadata {
  limit: number;
  totalCount: number;
  pageCount: number;
  limitKnown: boolean;
  totalCountKnown: boolean;
  error?: any;
}

export interface PagedResultPaged<T = any> {
  results: Observable<T[]>;
  accumulated: Observable<T[]>;
  isLoading: Observable<boolean>;
  isCompleted: Observable<boolean>;
  isError: Observable<boolean>;
  next(): void;
}

export interface PagedResultPreallocated<T = any> {
  results: Observable<T[]>;
  modify: (predicate: (value: T, index: number, obj: T[]) => boolean, action: (item: T) => T) => boolean;
  loadRange(start: number, end: number): Promise<void>;
}

export interface PagedResultAll<T = any> {
  results: Observable<T[]>;
  accumulated: Observable<T[]>;
}

export interface PagedResultRanged<T = any> {
  results: Observable<T[]>;
  accumulated: Observable<T[]>;
}


export interface PagedResult<T = any> {
  /** @description Get the paging metadata. */
  metadata(): Promise<PagedResultMetadata>;

  /** @description Get the raw data from API for selected page */
  raw(page?: number): Promise<PagedApiResult<T>>;

  /** @description Get the paged results. To get the next page, use the `next` method. Useful for infinite scroll etc.
   *  @param waitDoneOnNext Prevents calling next when there is already a request ongoing
   */
  paged(waitDoneOnNext?: boolean): PagedResultPaged<T>;

  /** @description Get the full results array where not loaded items are null. To load items, use the `loadRange` method.
   * Useful for virtual scroll etc.
   */
  preallocated(): PagedResultPreallocated<T>;

  /** @description Load all the pages. Loading a page starts after the previous one finishes. Useful when all items are needed. */
  all(): PagedResultAll<T>;

  allConcurrent(opts?: { concurrency?: number }): PagedResultAll<T>;

  /** @description Get the elements between a start and end index. Useful for custom paging. */
  range(start: number, end: number): PagedResultRanged<T>;

  /** @description Get all the elements in a single page. Useful for standard paging. */
  page(ind: number): Promise<T[]>;

  /** @description Asynchronously map every element to retrieve additional data using paging object. */
  map<TResult, TKey = string>(
    mapFn: (ids: TKey[]) => Promise<TResult[] | Map<TKey, TResult>>,
    zipFn: (item: T, dt: TResult) => boolean,
    selector?: keyof T,
  ): PagedResult<{ item: T, data: TResult }>;

  /** @description Map every item in the result to a new result */
  mapData<TResult>(mapFn: (item: T, index: number) => TResult): PagedResult<TResult>;
}

export type PagingCallback<T> = (next: number) => Observable<PagedApiResult<T>>;
export type PagingByDaysCallback<T> = (from: string, to: string) => Observable<PagedApiResult<T>>;
export type PagingByUsersCallback<T> = (users: string[]) => Observable<PagedApiResult<T>>;

@Injectable({
  providedIn: 'root',
})
export class PagingService {

  /**
   * Creates a helper that will fetch all or only requested pages from a given API.
   * The API callback should handle the request and return an object with `paging` property that defines how the data is paged.
   * If the `paging` property does not exist in the result, paging limit will be deduced automatically.
   * Alternatively, an explicit paging limit can be set when the `paging` property is empty.
   * @param api Function that will be called with each page offset, should return an observable
   * @param explicitLimit Optionally set the paging limit when it is not determined by the API side
   * @param explicitTotalCount Optionally set the total item count when it is not determined by the API side
   */
  getPagedResult<T>(api: PagingCallback<T>, explicitLimit?: number, explicitTotalCount?: number): PagedResult<T> {
    const cache: { [key: number]: Observable<PagedApiResult<T>> } = {};
    const apiCached = (next: number) => cache[next] ||
      (cache[next] = api(next).pipe(
        catchError((error: any) => of({ error })),
        shareReplay(1),
        switchMap((x) => ('error' in x) ? throwError(() => x.error) : of(x)),
      ));
    const preallocatedResults = new BehaviorSubject<T[]>(null);
    let pendingPreallocated = false;

    const res: PagedResult<T> = {
      metadata: async () => {
        try {
          const startRes = await lastValueFrom(apiCached(0), { defaultValue: { data: [] } as PagedApiResult<T> });

          const paging = startRes.paging || startRes.datePaging;
          const limitKnown = !!(explicitLimit || paging?.limit);
          const limit = explicitLimit || paging?.limit || startRes.data?.length;
          const totalCount = explicitTotalCount || paging?.totalCount;
          const totalCountKnown = typeof totalCount === 'number';
          const pageCount = (isFinite(totalCount) && isFinite(limit)) ? Math.max(1, Math.ceil(totalCount / limit)) : undefined;

          return {
            limit,
            limitKnown,
            totalCount,
            totalCountKnown,
            pageCount,
          };
        } catch (error) {
          return {
            limit: 1,
            limitKnown: false,
            totalCount: 0,
            totalCountKnown: false,
            pageCount: 1,
            error,
          };
        }
      },
      raw: async (page = 0) => {
        return await apiCached(page).toPromise();
      },
      paged: (waitDoneOnNext = false) => {
        const sbj = new ReplaySubject();
        sbj.next(true);

        const loadingSubjects = new BehaviorSubject(1);
        const metadataObs = from(res.metadata()).pipe(
          tap(() => {
            loadingSubjects.next(loadingSubjects.value - 1);
          }),
          shareReplay(),
        );
        const indArray = metadataObs
          .pipe(concatMap(metadata => iif(() => isFinite(metadata.pageCount),
            from(Array(metadata.pageCount || 0).fill(0).map((x, i) => [i * metadata.limit, metadata.limit])),
            sbj.pipe(map((x, i) => [i * metadata.limit, metadata.limit])),
          )));

        const nextSubject = new ReplaySubject();
        nextSubject.next(true);

        const errorSubject = new BehaviorSubject(false);


        let done = false;

        const results = zip(indArray, nextSubject.asObservable()).pipe(
          concatMap(([[ind]]) => {
            loadingSubjects.next(loadingSubjects.value + 1);

            return apiCached(ind).pipe(
              takeLast(1),
              map(x => x.data),
              tap(x => {
                loadingSubjects.next(loadingSubjects.value - 1);
                done = true;
                if (x.length === 0 || (explicitLimit && x.length < explicitLimit)) {
                  sbj.complete();
                } else {
                  sbj.next(true);
                }
              }),
              catchError((err) => {
                done = true;
                loadingSubjects.next(0);
                sbj.complete();
                errorSubject.next(err);
                errorSubject.complete();
                return throwError(() => err);
              }),
            );
          }),
          shareReplay(),
        );

        return {
          results,
          accumulated: results.pipe(scan((acc, cur) => [...acc, ...(cur || [])], [] as T[])),
          next: () => {
            if (sbj.closed) { return; }
            if (!waitDoneOnNext || done) {
              done = false;
              nextSubject.next(true);
            }
          },
          isLoading: loadingSubjects.pipe(map(x => x !== 0), distinctUntilChanged(), shareReplay()),
          isCompleted: from(lastValueFrom(sbj)).pipe(map(() => true), startWith(false), shareReplay()),
          isError: combineLatest([metadataObs, errorSubject]).pipe(map(([mt, err]) => !!mt.error || !!err)),
        };
      },
      preallocated: () => {
        if (preallocatedResults.value === null && !pendingPreallocated) {
          res.metadata().then(x => preallocatedResults.next(new Array(x.totalCount || 0).fill(undefined)));
          pendingPreallocated = true;
        }

        return {
          results: preallocatedResults,
          modify: (predicate, action) => {
            const list = preallocatedResults.value;
            if (!list) { return; }

            const ind = list.findIndex(predicate);

            if (ind < 0) { return false; }

            const item = list[ind];
            const modified = action(item);
            const newList = [...list];
            newList[ind] = modified;
            preallocatedResults.next(newList);
            return true;
          },
          loadRange: async (start: number, end: number) => {
            let loaded = true;
            const list = preallocatedResults.value;
            for (let index = start; index < end; index++) {
              loaded = loaded && !!list && (typeof list[index] !== 'undefined');
            }

            if (loaded) return;

            const results = res.range(start, end).accumulated;

            await lastValueFrom(results.pipe(
              tap(
                (items) => {
                  const result = preallocatedResults.value.slice();
                  for (let index = 0; index < items.length; index++) {
                    result[start + index] = result[start + index] || items[index];
                  }
                  preallocatedResults.next(result);
                }),
            ), { defaultValue: [] as T[] });
          },
        };
      },
      all: () => {
        const sbj = new ReplaySubject();
        sbj.next(true);

        const metadataObs = from(res.metadata());
        const indArray = metadataObs
          .pipe(concatMap(metadata => iif(() => isFinite(metadata.pageCount),
            from(Array(metadata.pageCount || 0).fill(0).map((x, i) => [i * metadata.limit, metadata.limit])),
            sbj.pipe(map((x, i) => [i * metadata.limit, metadata.limit])),
          )));

        const results = indArray.pipe(concatMap(([ind, limit]) => apiCached(ind).pipe(map(x => x.data), tap(x => {
          if (!x?.length || x.length < limit) {
            sbj.complete();
          } else {
            sbj.next(true);
          }
        }))));
        return {
          results,
          accumulated: results.pipe(scan((acc, cur) => [...acc, ...(cur || [])], [] as T[])),
        };
      },
      allConcurrent: ({ concurrency = 1 } = {}) => {
        const sbj = new ReplaySubject();
        sbj.next(true);

        const metadataObs = from(res.metadata());
        const indArray = metadataObs
          .pipe(concatMap(metadata => iif(() => isFinite(metadata.pageCount),
            from(Array(metadata.pageCount || 0).fill(0).map((x, i) => [i * metadata.limit, metadata.limit])),
            sbj.pipe(map((x, i) => [i * metadata.limit, metadata.limit])),
          )));

        const results = indArray.pipe(mergeMap(([ind, limit]) => apiCached(ind).pipe(
          tap(x => {
            if (!x.data?.length || x.data.length < limit) sbj.complete();
            else sbj.next(true);
          }),
          map(x => [ind, x.data] as const),
        ), concurrency));
        return {
          results: results.pipe(map(x => x[1])),
          accumulated: results.pipe(
            scan((acc, cur) => !cur ? acc : [...acc, cur || []], [] as [number, T[]][]),
            map(acc => acc.sort((a, b) => a[0] - b[0]).flatMap(x => x[1])),
          ),
        };
      },
      page: async (ind: number): Promise<T[]> => {
        const metadata = await res.metadata();
        if (isFinite(metadata.pageCount) && ind >= metadata.pageCount) {
          return [];
        }

        return apiCached(ind * metadata.limit).toPromise().then(x => x.data);
      },
      range: (start: number, end: number) => {
        const indArray = from(res.metadata())
          .pipe(concatMap(metadata => {
            if (metadata.totalCountKnown) end = Math.min(end, metadata.totalCount);

            const startPage = Math.floor(start / metadata.limit);
            const endPage = Math.floor((end - 1) / metadata.limit);
            const pageDif = endPage - startPage + 1;

            return Array(pageDif || 0).fill(0).map((x, i) => {
              const offset = (i + startPage) * metadata.limit;
              return {
                offset,
                startOffset: offset < start ? start - offset : 0,
                endOffset: offset + metadata.limit > end ? end - offset : metadata.limit,
              };
            });
          }));

        const results = indArray.pipe(concatMap(ind => apiCached(ind.offset).pipe(map(x => x.data.slice(ind.startOffset, ind.endOffset)))));

        return {
          results,
          accumulated: results.pipe(scan((acc, cur) => [...acc, ...cur], [] as T[])),
        };
      },
      map: <TResult, TKey = string>(
        mapFn: (ids: TKey[]) => Promise<TResult[] | Map<TKey, TResult>>,
        zipFn: (item: T, dt: TResult) => boolean,
        selector: keyof T = 'id' as any,
      ) => {
        const cb: PagingCallback<{ item: T, data: TResult }> = (next: number) => {
          return api(next).pipe(switchMap(result => {
            if (!result?.data) { return null; }
            const itemIds = result.data.map(y => y[selector] as unknown as TKey);
            return mapFn(itemIds).then(mapped => ({
              ...result,
              data: result.data.map(item => ({
                item,
                data: mapped instanceof Map ? mapped.get(item[selector] as unknown as TKey) : mapped.find(r => zipFn(item, r)),
              })),
            }));
          }));
        };

        return this.getPagedResult(cb, explicitLimit, explicitTotalCount);
      },

      mapData: <TResult>(
        mapFn: (item: T, index: number) => TResult,
      ) => {
        const cb: PagingCallback<TResult> = (next: number) => {
          return api(next).pipe(map(result => {

            return {
              ...result,
              data: result.data.map((item, index) => mapFn(item, next + index)),
            };
          }));
        };

        return this.getPagedResult(cb, explicitLimit, explicitTotalCount);
      },
    };

    return res;
  }

  pageByDays<T>(fromDate: string, toDate: string, api: PagingByDaysCallback<T>, limit: number = 60, timezone: string = 'UTC') {
    limit = limit ?? 30;
    const fromDt = DateTime.fromISO(fromDate).setZone(timezone);
    const toDt = DateTime.fromISO(toDate).setZone(timezone);

    const totalDays = toDt.diff(fromDt, 'days').days;
    const pageSize = limit > 0 ? limit : totalDays;

    return this.getPagedResult((next) => api(
      fromDt.plus({ days: next }).toISO(),
      DateTime.min(fromDt.plus({ days: next + limit }), toDt).toISO(),
    ), pageSize, totalDays);
  }

  pageByUsers<T>(users: string | string[], api: PagingByUsersCallback<T>, limit: number = 100) {
    limit = limit || 100;
    const usersArray = typeof users === 'string' ? users.split(',') : users;
    return this.getPagedResult((next) => api(usersArray.slice(next, next + limit)), limit);
  }

  empty<T>() {
    return this.getPagedResult<T>(() => of({ data: [], paging: { totalCount: 0, cur: 0, limit: 1 } }));
  }

  from<T>(array: T[], limit: number = 100) {
    limit = limit || 100;
    return this.getPagedResult((next) => of({
      data: array.slice(next, next + limit),
      paging: {
        totalCount: array.length,
        cur: 0,
        limit,
      },
    }), limit, array.length);
  }
}
