import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { noSideBarUrls } from 'src/app/components/nav-sidebar/nav-sidebar.component';
import { RevenueHeroModel } from 'src/app/integrations/revenuehero/revenue-hero-model';
import { MeetingBookedEvent } from 'src/app/integrations/revenuehero/types';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { PopLoading, PushLoading } from 'src/app/store/loading/loading.actions';
import { TrialWidgetState } from 'src/app/store/trial-widget/trial-widget.state';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';
@UntilDestroy()
@Component({
  selector: 'app-trial-widget',
  templateUrl: './trial-widget.component.html',
  styleUrls: ['./trial-widget.component.scss'],
})
export class TrialWidgetComponent implements OnInit, AfterViewInit  {
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.billingMode) billingMode$: Observable<BillingDetailsApiData['billingMode']>;
  @Output() panelOpened = new EventEmitter<number>();
  @Output() panelClosed = new EventEmitter<unknown>();

  hasCard = false;
  @Input() isDragging = false;

  //properties related to getting started screen
  panelOpenState = false;
  readonly widgetHeightMinimized = 40;
  readonly fullWidgetHeightWith = 140;
  readonly fullWidgetHeightWithBookACall = 200;

  @Input()
  set isCollapsed(willBeCollapsed: boolean) {
    if (!willBeCollapsed && this._isCollapsed) {
      this.trackWidgetViewed();
    }

    this._isCollapsed = willBeCollapsed;
  }

  get isCollapsed() { return this._isCollapsed; }

  @Input() variant: 'raised' | 'outlined' | 'accordion' = 'raised';
  @Input() isOnFloatingWidget?: boolean = false;

  private _isCollapsed: boolean;
  trialDaysLeft = 0;
  trialStartDate: DateTime;
  trialEndDate: DateTime;
  currentUrl: string;
  user: AuthUser;
  revenueHeroInit: RevenueHeroModel;
  isWidgetExpanded = true;
  basePlan = '';

  @HostBinding('class.outlined') get outlined() {
    return this.variant === 'outlined';
  }

  @HostBinding('class.collapsed') get collapsed() {
    return this.isCollapsed;
  }

  @HostBinding('class.dragging') get draggingClass(): boolean {
    return this.isDragging;
  }

   btnClickTrackData() {
    return {
      page: this.variant !== 'accordion' ? 'Clicked Upgrade On Trial Widget' : 'Clicked Choose Your Plan On Getting Started Trial Widget',
      trialstartdate: this.trialStartDate?.toFormat('yyyy-MM-dd'),
      trialenddate: this.trialEndDate?.toFormat('yyyy-MM-dd'),
      currenturl: this.currentUrl,
    };
  }

  constructor(
      private readonly router: Router,
      private readonly store: Store,
      private readonly segment: SegmentService,
      private readonly growthbookService: GrowthBookService,
      private readonly cdr: ChangeDetectorRef,
  ) {
    this.user = this.store.selectSnapshot(AuthState.user);

    let shouldDispatch = false;

    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate' || event.restoredState) {
          shouldDispatch = true;
        }
      } else if (event instanceof NavigationEnd) {
        shouldDispatch = true;
        this.currentUrl = event.url;
      }
    });

    router.events.pipe(
      untilDestroyed(this),
      filter((event) => event instanceof NavigationEnd
        && !noSideBarUrls.some((url) => event.urlAfterRedirects.includes(url)),
      ),
      filter(() => !this.isCollapsed && this.variant !== 'accordion'),
    ).subscribe(() => {
      this.trackWidgetViewed();
    });
  }

  private trackWidgetViewed() {
    if (this.variant === 'accordion') return;
    this.segment.trackInAppEvents('Trial Widget Viewed', {
      status: 'trial',
      version: this.store.selectSnapshot(TrialWidgetState.getTrialWidgetType),
    });
  }

  ngOnInit() {
    this.displayTrialDays();
  }

  ngAfterViewInit() {
    this.waitForHubSpotConversations();
  }

  private waitForHubSpotConversations(maxRetries = 30, retryCount = 0): void {
    // Early return if not on floating widget
    if (!this.isOnFloatingWidget) return;

    // Check if HubSpot Conversations is loaded
    const isHubSpotLoaded = window.HubSpotConversations && typeof window.HubSpotConversations === 'object';

    if (isHubSpotLoaded) {
      // HubSpot is loaded, attempt to set the iframe style
      this.setIframeStyle(maxRetries, retryCount);
    } else {
      // Retry if HubSpot is not loaded
      this.retryOrLog(maxRetries, retryCount);
    }
  }

  private setIframeStyle(maxRetries: number, retryCount: number): void {
    const hsChatContainer = document.getElementById('hubspot-messages-iframe-container');

    if (hsChatContainer) {
      if (window.innerWidth < 400) {
        hsChatContainer.style.marginBottom = '50px';
        hsChatContainer.style.marginRight = '10px';
      } else {
        hsChatContainer.style.marginRight = '380px';
      }
    } else if (retryCount < maxRetries) {
      // Retry after a delay if iframe is not available
      setTimeout(() => this.setIframeStyle(maxRetries, retryCount + 1), 1000);
    }
  }

  private retryOrLog(maxRetries: number, retryCount: number): void {
    if (retryCount < maxRetries) {
      setTimeout(() => this.waitForHubSpotConversations(maxRetries, retryCount + 1), 1000);
    }
  }

  private getBasePlan(pricingPlan) {
    if (!pricingPlan) return '';

    const basePlan = pricingPlan.includes('_') ? pricingPlan.split('_')[0] : pricingPlan;
    return basePlan.charAt(0).toUpperCase() + basePlan.slice(1);
  }

  private hasCreditCard(company: AuthCompany) {
    return company?.companySettings?.custom?.completedStep?.addCreditCard
      || company?.userSettings?.custom?.completedStep?.addCreditCard
      || false;
  }

  private async displayTrialDays() {
    this.company$
      .pipe(untilDestroyed(this))
      .subscribe(company => {
        this.basePlan = this.getBasePlan(company?.pricingPlan);
        this.hasCard = this.hasCreditCard(company);

        if (company) {
          this.trialStartDate = DateTime.fromISO(company.companyCreatedAt);
          this.trialEndDate = DateTime.fromISO(company.subscription.expires);
          this.trialDaysLeft = Math.floor(this.trialEndDate.diff(DateTime.now(), 'days').days);
        }
      });
  }

  async trialUpgrade() {
    const currentPath = this.router.url;
    const subscription = this.store.selectSnapshot(AuthState.company)?.subscription;

    let path = subscription?.provider === 'paddle'
      ? '/new-subscribe-plans'
      : '/subscribe-plans';

    path = !this.hasCard ? path : '/new-billing';
    this.router.navigate([path], {queryParams: {url: encodeURI(currentPath)}});
  }

  get hasBookACallAccess() {
    return this.growthbookService.getFeatureValue('book-call-sales-v2');
  }

  contactSales() {
    this.store.dispatch(new PushLoading());
    this.segment.trackInAppEvents(this.variant !== 'accordion' ? 'Clicked Book A Call On Trial Widget' : 'Clicked Book a Call On Getting Started Trial Widget');
    this.revenueHeroInit = new RevenueHeroModel({
      user: this.user,
      routerId: environment.trialWidgetRevenueHeroRouterId,
      scheduleId: environment.trialWidgetRevenueHeroScheduleId,
    });
      this.revenueHeroInit.initialize();
      this.store.dispatch(new PopLoading());
      this.revenueHeroInit.onMeetingBooked = (e: MeetingBookedEvent) => {
        this.trackContactSales(e);
      };
  }

  private trackContactSales(e: MeetingBookedEvent) {
    const {
      booker_email: bookerEmail,
      booking_participants: bookingParticipants,
      booker_name: bookerName,
      reschedule_count: rescheduleCount,
      status: meetingStatus,
    } = e.data.meeting.attributes;

    this.segment.trackInAppEvents('Book a Call Confirmation Pop up', {
      bookingParticipants,
      bookerEmail,
      bookerName,
      rescheduleCount,
      meetingStatus,
    });
  }

  toggleOpenPanelState() {
    this.panelOpenState = true;
    this.panelOpened.emit(this.hasBookACallAccess ? this.fullWidgetHeightWithBookACall : this.fullWidgetHeightWith);
  }

  toggleClosePanelState() {
    this.panelOpenState = false;
    this.panelClosed.emit(this.widgetHeightMinimized);
    this.cdr.markForCheck();
  }
}
