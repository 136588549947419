import { Component, ElementRef, AfterViewInit, OnDestroy, ViewChild, Renderer2, Input, Output, EventEmitter } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let YT;
@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoContainer', { static: false }) videoContainer!: ElementRef;
  @Input() videoId = '';
  @Output() videoEnded = new EventEmitter<void>();

  player: any;
  isVideoEnded = false;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.loadYouTubeScript().then(() => {
      if (window['YT'] && window['YT'].Player) {
        this.initializePlayer();
      } else {
        window['onYouTubeIframeAPIReady'] = () => this.initializePlayer();
      }
    });
  }

  loadYouTubeScript(): Promise<void> {
    return new Promise((resolve) => {
      if (window['YT'] && window['YT'].Player) {
        resolve();
      } else {
        if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
          const script = this.renderer.createElement('script');
          script.src = 'https://www.youtube.com/iframe_api';
          script.onload = () => resolve();
          document.body.appendChild(script);
        } else {
          resolve();
        }
      }
    });
  }

  initializePlayer(): void {
    if (!window['YT'] || !window['YT'].Player) {
      setTimeout(() => this.initializePlayer(), 500);
      return;
    }

    this.player = new YT.Player('videoPlayer', {
      videoId: this.videoId,
      playerVars: {
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
      },
      events: {
        onReady: (event: any) => {
          this.player = event.target;
        },
        onStateChange: (event: any) => this.onPlayerStateChange(event),
      },
    });
  }

  onPlayerStateChange(event: any): void {
    if (event.data === YT.PlayerState.ENDED) {
      this.videoEnded.emit();
      this.reloadVideo();
    }
  }

  reloadVideo(): void {
    this.isVideoEnded = false;
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }

    setTimeout(() => this.initializePlayer(), 200);
  }

  ngOnDestroy(): void {
    if (this.player && this.player.destroy) {
      this.player.destroy();
    }
  }
}
