<app-shared-banner *ngIf="showBanner$ | async" #sharedTopBanner [svgIcon]="banner.svgIcon" [icon]="banner.icon"
  [attr.aria-label]="banner.ariaLabel" [attr.data-type]="banner.type" [attr.data-icon-color]="banner.iconColor">
  <span banner-header [innerHTML]="banner.message.replace('\n', '<br/>')"></span>

  <div banner-buttons fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
        *ngIf="banner.primaryButton || banner.secondaryButton"
        fxLayoutGap="8px" fxLayoutGap.xs="16px" fxFlexOffset="8px">
  <button mat-button mat-raised-button (click)="banner.secondaryButton?.action(sharedTopBanner)"
          [trackClick]="banner.trackingInfo" *ngIf="banner.secondaryButton">
    {{ banner.secondaryButton.text | translate }}
  </button>
  <button mat-button mat-stroked-button (click)="banner.primaryButton?.action(sharedTopBanner)"
          [trackClick]="banner.trackingInfo" *ngIf="banner.primaryButton">
    {{ banner.primaryButton.text | translate }}
  </button>
</div>
</app-shared-banner>
