import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import saveAs from 'file-saver';
import { combineLatest, lastValueFrom, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { BrandingService } from '../branding.service';
import { downloadBashTemplate, shouldUseUbuntuShellScript } from './install-script-generator-bash-linux';
import { getInteractiveInstallerUrl, getRenamedWindowsSilentInstallerUrl, getSilentInstallCommand } from './installer';

export type DownloadPlatform = '' | 'win' | 'mac' | 'linux';

export enum OS {
  Windows = 0,
  Mac = 1,
  Linux = 2,
}

export enum DesktopAppPlatforms {
  Windows = 'win',
  Mac = 'mac',
  Linux = 'linux',
}


interface VersionResponse {
  version: string;
}

export interface DownloadData {
  platform: string;
  version: string;
  appName: string;
  href: string;
  hrefMsi?: string;
  cmd?: string;
}

export interface FileFetcher extends DownloadData {
  fetch?: () => void;
  type: 'silent' | 'interactive';
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  readonly downloadURL = 'https://download.timedoctor.com';
  readonly cloudHosts = ['app.staff.com', 'app.timedoctor.com', '2.timedoctor.com', '2a.timedoctor.com'];
  readonly customCloudHostsEnding = '.timedoctor.com';
  readonly testDeploymentEnding = 'timedoctor.vercel.app';
  readonly isMobileDevice: boolean = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|BlackBerry|BB10|Samsung|SM-/i.test(window.navigator.userAgent);

  constructor(private http: HttpClient, private branding: BrandingService, private store: Store) { }

  determineOS(): DownloadPlatform {
    if (window.navigator.userAgent.indexOf('Win') !== -1) {
      return 'win';
    } else if (window.navigator.userAgent.indexOf('Mac') !== -1) {
      return 'mac';
    } else if (window.navigator.userAgent.indexOf('Ubuntu') !== -1
      || (window.navigator.userAgent.indexOf('Linux') !== -1 && !this.isMobileDevice)) {
      return 'linux';
    }

    return '';
  }

  getDeployment(hostname: string) {
    const cleanHostname = hostname ? hostname.toLowerCase().trim() : undefined;

    if (this.cloudHosts.indexOf(cleanHostname) >= 0) {
      return 'cloud';
    }

    if (cleanHostname.endsWith(this.customCloudHostsEnding) || cleanHostname.endsWith(this.testDeploymentEnding)) {
      return cleanHostname;
    }

    if (/^localhost(:\d+)?$/.exec(cleanHostname)) {
      return 'localhost';
    }

    return 'on-premise';
  }

  public async getDownloadData(
    platform: DownloadPlatform,
    companyId: string = undefined,
    userId: string = undefined,
    silent: boolean = false,
    hostname: string = undefined,
  ): Promise<DownloadData> {
    // get branding app url and remove http:// or https:// prefixes
    const brandingAppUrl = this.branding.getAppUrl();
    const brandingHost = brandingAppUrl?.replace(/^https?:\/\//, '');

    const host = hostname || brandingHost || location.host;

    let appName = this.branding.getDownloadAppName();
    if (silent === true) {
      appName = 'staff-app';
    }

    const { version } = await lastValueFrom(this.http.get<VersionResponse>(`${this.downloadURL}/desktop-app-version`, {
      params: {
        os: platform,
        type: silent ? 'silent' : 'interactive',
        download_page: true,
        deployment: this.getDeployment(host),
        company_id: companyId,
        user_id: userId,
      },
    }));

    const data: DownloadData = {
      platform,
      version,
      appName,
      href: '#',
    };

    if (silent) {
      if (platform === 'win') {
        data.cmd = data.href = getRenamedWindowsSilentInstallerUrl(host, companyId, version);
      } else {
        data.cmd = getSilentInstallCommand(platform, host, companyId, version);
      }
    } else {
      data.href = getInteractiveInstallerUrl(platform, appName, version);
      if (platform === 'win') {
        data.hrefMsi = getInteractiveInstallerUrl(platform, appName, version, true);
      }
    }

    return data;
  }

  public async getFileFetcher(
    platform: DownloadPlatform,
    companyId: string = undefined,
    userId: string = undefined,
    silent: boolean = false,
  ): Promise<FileFetcher> {
    const data: FileFetcher = {
      ...await this.getDownloadData(platform, companyId, userId, silent),
      type: silent ? 'silent' : 'interactive',
    };

    if (silent) {
      return data;
    }

    if (platform === 'linux' && shouldUseUbuntuShellScript(data.version)) {
      return {
        ...data,
        href: 'javascript:void(0);',
        fetch: () => downloadBashTemplate(data.version, data.appName),
      };
    }

    return data;
  }

  public downloadBatFile(version: string) {
    const batCode = `
set VERSION=${version}
set URL_BASE=https://download.timedoctor.com/%VERSION%/windows/interactive
set INSTALLER_FILE=${this.branding.getDownloadAppName()}-setup-%VERSION%-windows.msi
set DOWNLOAD_DIR=%SystemDrive%\\td-latest-installer
set INSTALLER_DOWNLOADED_PATH=%DOWNLOAD_DIR%\\%INSTALLER_FILE%
rmdir "%DOWNLOAD_DIR%" /q /s
mkdir "%DOWNLOAD_DIR%"
bitsadmin.exe /transfer "Download_TD_%VERSION%" "%URL_BASE%/%INSTALLER_FILE%" "%INSTALLER_DOWNLOADED_PATH%"
start /wait msiexec /passive /qb /i "%INSTALLER_DOWNLOADED_PATH%"
rmdir "%DOWNLOAD_DIR%" /q /s`;

    const blob = new Blob([batCode], {
      type: 'text/plain;charset=utf-8;',
    });
    saveAs(blob, `TD2-Interactive-v${version}.bat`);
  }

  public getFetchers() {
    const company = this.store.selectSnapshot(AuthSelectors.company);
    const user = this.store.selectSnapshot(AuthSelectors.user);
    const trackingMethod = company.companySettings?.trackingMode;
    const isSilent = trackingMethod === 'silent';

    return combineLatest([
      this.getFileFetcher('win', company.id, user.id, isSilent),
      this.getFileFetcher('mac', company.id, user.id, isSilent),
      this.getFileFetcher('linux', company.id, user.id, isSilent),
    ]).pipe(
      map(([win, mac, linux]) => ({ win, mac, linux })),
      shareReplay(1),
      catchError(() => of('error' as const)),
    );
  }
}
