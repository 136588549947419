import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  init() {
    if(environment.amplitudeApiKey) {
      amplitude.init(environment.amplitudeApiKey, { autocapture: true, fetchRemoteConfig: true });
    }
  }

  trackEvent(eventName: string, eventData?: any) {
    amplitude.track(eventName, eventData);
  }
}
