import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BillingError } from 'src/app/pages/new-billing/models/billing-error';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { environment } from 'src/environments/environment';
import { HttpRequestOptions } from '../api.service';
import { API_URL } from '../app.constants';

export type BillingGetEndpoints =
  | 'customer/billing-details'
  | 'customer/receipts'
  | 'customer/receipt-details'
  | 'customer/create-transaction'
  | 'customer/invoice-preview'
  | 'customer/switch-billing-period'
  | 'plan'
  | 'discount';

export type BillingPutEndpoints =
  | 'customer/cancel-subscription'
  | 'customer';

export type BillingPatchEndpoints =
  | 'customer';

export type BillingPostEndpoints =
  | 'customer/payment-retry';

/**
 * This service is responsible for making requests to the billing API. It exposes the HTTP methods that
 * are allowed to be used to make requests to the billing API.
 */
@Injectable({
  providedIn: 'root',
})
export class BillingApiService {
  private readonly billingApiUrl = environment.billingApiUrl;

  constructor(
    @Inject(API_URL) private apiUrl: string,
    private httpClient: HttpClient,
    private store: Store,
  ) {

  }

  /**
   * This method is used to make a GET request to the billing API.
   *
   * @param endpoint The endpoint to make the request to.
   * @param options The options to pass to the request.
   *
   * @throws BillingError with `errorCode: 'api_url_mismatch' if the API URL is not the same as the one in the
   *   environment.
   * @throws BillingError with `errorCode: 'no_billing_access' if the user does not have access to billing.
   */
  public get<T>(endpoint: BillingGetEndpoints, options: HttpRequestOptions = {}) {
    if (environment.apiUrl !== this.apiUrl) {
      return throwError(() => new BillingError({
        errorCode: 'api_url_mismatch',
        message: 'API URL mismatch',
        statusCode: 400,
      }));
    }

    const company = this.store.selectSnapshot(AuthSelectors.company);
    const isUserActive = this.store.selectSnapshot(AuthSelectors.isUserActive);

    if (!isUserActive) {
      return throwError(() => new BillingError({
        errorCode: 'no_billing_access',
        message: 'User does not have access to billing',
        statusCode: 403,
      }));
    }

    return this.httpClient.get<T>(`${this.billingApiUrl}/${endpoint}`, options).pipe(
      catchError((err) => {
        Sentry.captureMessage('Error in request to the Billing API', {
          level: 'warning',
          extra: {
            error: err,
            endpoint,
            company,
            user: this.store.selectSnapshot(AuthSelectors.user),
          },
        });

        return throwError(() => new BillingError({
          errorCode: 'unknown_error',
          message: err.message,
          statusCode: err.statusCode,
        }));
      }),
    );
  }

  public put<T, D = Record<string, unknown>>(
    endpoint: BillingPutEndpoints,
    body: D,
    options: HttpRequestOptions = {},
  ) {
    return this.httpClient.put<T>(`${this.billingApiUrl}/${endpoint}`, body, options);
  }

  public post<T, D = Record<string, unknown>>(
    endpoint: BillingPostEndpoints,
    body: D,
    queryParams: Record<string, string> = {},
    options: HttpRequestOptions = {},
  ) {
    let params = new HttpParams();
    for (const key of Object.keys(queryParams)) {
      params = params.set(key, queryParams[key]);
    }

    const url = `${this.billingApiUrl}/${endpoint}`;

    return this.httpClient.post<T>(url, body, { params, ...options });
  }


  public patch<T, D = Record<string, unknown>>(
    endpoint: BillingPatchEndpoints,
    body: D,
    options: HttpRequestOptions = {},
  ) {
    return this.httpClient.patch<T>(`${this.billingApiUrl}/${endpoint}`, body, options);
  }
}
