<ng-container *ngIf="authorized$ | async">
  <ng-container *ngIf="company$ | async as company">
    <mat-toolbar *ngIf="user$ | async as user">
      <button *ngIf="withNavSidebar" mat-icon-button (click)="sidebarMenuClick.emit()" class="menu-toggle nav-sidebar">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="home-link" routerLinkActive="active">
        <img [src]="branding.getLogoGray()" alt="Timedoctor2 Logo" width="32">
      </a>
      <button *ngIf="!hasMandatoryCall" mat-icon-button (click)="navOpen()" fxHide.gt-sm class="menu-toggle">
        <div class="header-badge" *ngIf="$any(menuDef)?.sideNavHeaderBadge?.(company, injector)"></div>
        <mat-icon>menu</mat-icon>
      </button>

      <div fxHide.lt-md fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="stretch stretch" *ngIf="!hasMandatoryCall">
        <ng-container *ngFor="let item of menuDef.items">
          <ng-container *ngIf="!item.condition || item.condition(company, injector)">
            <ng-container *ngIf="!$any(item).children; else buttonWithChildren">

              <a class="toolbar-button stand-alone-btn" [routerLink]="$any(item).routerLink" routerLinkActive="active">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <ng-container *ngIf="$any(item?.badge) as badgeObj">
                    <ng-container *ngIf="badgeObj.length; else singleBadge">
                      <ng-container *ngFor="let item of badgeObj">
                        <ng-template *ngTemplateOutlet="badgeTemplate; context: { badgeDetail: item }"></ng-template>
                      </ng-container>
                    </ng-container>
                    <ng-template #singleBadge>
                      <ng-template *ngTemplateOutlet="badgeTemplate; context: { badgeDetail: badgeObj }"></ng-template>
                    </ng-template>
                  </ng-container>

                  <ng-template #badgeTemplate let-badgeDetail="badgeDetail">
                    <div class="mini-badge" *ngIf="!badgeDetail.condition || badgeDetail.condition(company, injector)">
                    </div>
                    <ng-template
                      *ngTemplateOutlet="badge; context: { icon: badgeDetail.icon, iconTooltip: badgeDetail.iconTooltip, text: badgeDetail.text, condition: badgeDetail.condition, tooltip: badgeDetail.tooltip }">
                    </ng-template>
                  </ng-template>
                  <span>
                    {{ item.name | translate | uppercase }}
                  </span>
                </div>
              </a>
            </ng-container>



            <ng-template #buttonWithChildren>
              <button mat-button class="toolbar-button" [matMenuTriggerFor]="subMenu"
                [class.active]="anyChildActive($any(item))">
                <div class="header-badge" *ngIf="$any(item)?.showHeaderBadgeIcon?.(company, injector)"></div>
                {{ item.name | translate | uppercase }}
                <mat-icon inline>keyboard_arrow_down</mat-icon>
              </button>

              <mat-menu #subMenu="matMenu">
                <ng-container *ngFor="let sub of $any(item).children">
                  <ng-container *ngIf="!sub.condition || sub.condition(company, injector)">
                    <ng-container *ngIf="sub.routerLink; else menuLabel">
                      <a mat-menu-item [routerLink]="sub.routerLink" routerLinkActive="active" style="min-width: 180px;"
                        [fragment]="sub.fragment"
                        [routerLinkActiveOptions]="sub.fragment ? customRouterLinkActiveOptions : {'exact': false}">
                        {{ sub.name | translate }}

                        <ng-container *ngIf="sub.badge">
                          <ng-container *ngIf="sub.badge.length; else singleBadge">
                            <ng-container *ngFor="let item of sub.badge">
                              <ng-template
                                *ngTemplateOutlet="badge; context: { icon: item.icon, iconTooltip: item.iconTooltip, text: item.text, condition: item.condition, tooltip: item.tooltip }"></ng-template>
                            </ng-container>
                          </ng-container>
                          <ng-template #singleBadge>
                            <ng-template
                              *ngTemplateOutlet="badge; context: { icon: sub.badge.icon, iconTooltip: sub.badge.iconTooltip, text: sub.badge.text, condition: sub.badge.condition, tooltip: sub.badge.tooltip }"></ng-template>
                          </ng-template>
                        </ng-container>
                      </a>
                    </ng-container>
                    <ng-template #menuLabel>
                      <div class="menu-label">{{ sub.name | translate }}</div>
                    </ng-template>
                  </ng-container>
                </ng-container>


              </mat-menu>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>

      <div style="flex: 1 1 auto;"></div>

      <app-announce-button *ngIf="(isManager$ | async)"> </app-announce-button>

      <button *ngIf="helpButtonEnabled(company, branding.isDefault)" mat-icon-button [matMenuTriggerFor]="helpMenu"
        class="help-button">
        <mat-icon svgIcon="help_outline"></mat-icon>
      </button>

      <mat-menu #helpMenu="matMenu" xPosition="before" yPosition="below">
        <ng-container *ngIfFeature="'header-help-links'">
          <a *ngFor="let helpLink of getHelpLinks()" mat-menu-item target="_blank" rel="noopener"
            href="{{helpLink.url}}">{{ ('header.help.titles.' + helpLink.title) | translate | titlecase }}</a>
          <mat-divider *ngIf="getHelpLinks().length"></mat-divider>
        </ng-container>
        <ng-container *ngIfFeature="'help-center-link'">
          <a mat-menu-item target="_blank" rel="noopener" href="https://support.timedoctor.com/en/support/home"
            *ngIf="helpCenterEnabled(company, branding.isDefault)">{{
            'header.help.titles.helpCenter' | translate | titlecase }}</a>
        </ng-container>
        <button mat-menu-item *ngIf="contactUsEnabled(company)" (click)="openBubbleMessage()">{{
          'header.help.titles.contactUs' | translate | titlecase }}
        </button>
        <a mat-menu-item target="_blank" rel="noopener" href="{{employeePrivacyDisclaimerUrl}}"
          *ngIf="employeePrivacyDisclaimerUrl">{{
          'header.help.titles.employeePrivacyDisclaimer' | translate | titlecase }}</a>
        <a mat-menu-item target="_blank" rel="noopener" href="{{contractorPrivacyDisclaimerUrl}}"
          *ngIf="contractorPrivacyDisclaimerUrl">{{
          'header.help.titles.contractorPrivacyDisclaimer' | translate | titlecase }}</a>
        <a mat-menu-item target="_blank" rel="noopener" [href]="trainingGuideUrl" *ngIf="trainingGuideUrl">{{
          'header.help.titles.trainingGuide' | translate | titlecase }}</a>
      </mat-menu>

      <button *ngIf="hasMultipleCompanies else singleCompany" class="toolbar-button ellipsis company-button" mat-button
        [matMenuTriggerFor]="companySelectMenu">
        <span>{{ company.name }}</span>
      </button>

      <ng-template #singleCompany>
        <button class="toolbar-button ellipsis company-button" mat-button>
          <span>{{ company.name }}</span>
        </button>
      </ng-template>

      <button fxFlex="0 10000 auto" class="toolbar-button ellipsis profile-button" mat-button
        [matMenuTriggerFor]="profileMenu">
        <span class="user-name" fxHide.xs>{{ user.name }}</span>
        <app-avatar [name]="user.name" [size]="32" [textSizeRatio]="32/14"></app-avatar>
      </button>

      <mat-menu #companySelectMenu="matMenu" xPosition="before" yPosition="below">
        <button *ngFor="let sCompany of companies$ | async" mat-menu-item style="min-width: 180px;"
          (click)="company !== sCompany && selectCompany(sCompany)">{{ sCompany.name }}</button>
      </mat-menu>

      <mat-menu #profileMenu="matMenu" xPosition="before" yPosition="below">
        <a mat-menu-item routerLink="profile-edit">{{ 'header.editProfile' | translate }}</a>
        <button mat-menu-item routerLink="/logout" style="min-width: 160px;">{{ 'header.logout' | translate }}</button>
      </mat-menu>
    </mat-toolbar>
    <ng-template #badge let-condition="condition" let-text="text" let-icon="icon" let-iconTooltip="iconTooltip"
      let-tooltip="tooltip">
      <div *ngIf="!condition || condition(company, injector)" [matTooltip]="tooltip | translate" class="badge"
        style="margin-left: 8px;">
        <mat-icon *ngIf="icon" class="badge-icon" [matTooltip]="iconTooltip | translate">{{ icon
          }}</mat-icon>
        <div *ngIf="text" class="badge-text">{{ text | translate }}</div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
