import { PlanApiData, PlanId } from 'src/app/pages/new-billing/types/api.types';
import { LegacyPricingPlans, PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';

export const planIdToPricingPlan: Record<PlanId, LegacyPricingPlans> = {
  standard_yearly: 'standard_plan_annual',
  standard_semi_yearly: 'standard_plan_semi_annual',
  standard_quarterly: 'standard_plan_quarter',
  standard_monthly: 'standard_plan',
  basic_yearly: 'basic_plan_annual',
  basic_semi_yearly: 'basic_plan_semi_annual',
  basic_quarterly: 'basic_plan_quarter',
  basic_monthly: 'basic_plan',
  premium_yearly: 'premium_plan_annual',
  premium_semi_yearly: 'premium_plan_semi_annual',
  premium_quarterly: 'premium_plan_quarter',
  premium_monthly: 'premium_plan',
  standard_yearly_01: 'standard_plan_annual',
  standard_semi_yearly_01: 'standard_plan_semi_annual',
  standard_quarterly_01: 'standard_plan_quarter',
  standard_monthly_01: 'standard_plan',
  basic_yearly_01: 'basic_plan_annual',
  basic_semi_yearly_01: 'basic_plan_semi_annual',
  basic_quarterly_01: 'basic_plan_quarter',
  basic_monthly_01: 'basic_plan',
};

export const pricingPlanToPlanId: Record<LegacyPricingPlans, PlanId> = {
  basic_plan_annual: 'basic_yearly_01',
  basic_plan_semi_annual: 'basic_semi_yearly_01',
  basic_plan_quarter: 'basic_quarterly_01',
  basic_plan: 'basic_monthly_01',
  standard_plan_annual: 'standard_yearly_01',
  standard_plan_semi_annual: 'standard_semi_yearly_01',
  standard_plan_quarter: 'standard_quarterly_01',
  standard_plan: 'standard_monthly_01',
  premium_plan_annual: 'premium_yearly',
  premium_plan_semi_annual: 'premium_semi_yearly',
  premium_plan_quarter: 'premium_quarterly',
  premium_plan: 'premium_monthly',
};

export const tierUnderPlan: Record<PlanId, PlanId> = {
  basic_yearly: 'basic_yearly',
  basic_semi_yearly: 'basic_semi_yearly',
  basic_quarterly: 'basic_quarterly',
  basic_monthly: 'basic_monthly',
  basic_yearly_01: 'basic_yearly_01',
  basic_semi_yearly_01: 'basic_semi_yearly_01',
  basic_quarterly_01: 'basic_quarterly_01',
  basic_monthly_01: 'basic_monthly_01',
  standard_yearly: 'basic_yearly_01',
  standard_semi_yearly: 'basic_semi_yearly_01',
  standard_quarterly: 'basic_quarterly_01',
  standard_monthly: 'basic_monthly_01',
  premium_yearly: 'standard_yearly_01',
  premium_semi_yearly: 'standard_semi_yearly_01',
  premium_quarterly: 'standard_quarterly_01',
  premium_monthly: 'standard_monthly_01',
  standard_yearly_01: 'basic_yearly_01',
  standard_semi_yearly_01: 'basic_semi_yearly_01',
  standard_quarterly_01: 'basic_quarterly_01',
  standard_monthly_01: 'basic_monthly_01',
};

export const tierOverPlan: Record<PlanId, PlanId> = {
  basic_yearly: 'standard_yearly_01',
  basic_semi_yearly: 'standard_semi_yearly_01',
  basic_quarterly: 'standard_quarterly_01',
  basic_monthly: 'standard_monthly_01',
  basic_yearly_01: 'standard_yearly_01',
  basic_semi_yearly_01: 'standard_semi_yearly_01',
  basic_quarterly_01: 'standard_quarterly_01',
  basic_monthly_01: 'standard_monthly_01',
  standard_yearly: 'premium_yearly',
  standard_semi_yearly: 'premium_semi_yearly',
  standard_quarterly: 'premium_quarterly',
  standard_monthly: 'premium_monthly',
  premium_yearly: 'premium_yearly',
  premium_semi_yearly: 'premium_semi_yearly',
  premium_quarterly: 'premium_quarterly',
  premium_monthly: 'premium_monthly',
  standard_yearly_01: 'premium_yearly',
  standard_semi_yearly_01: 'premium_semi_yearly',
  standard_quarterly_01: 'premium_quarterly',
  standard_monthly_01: 'premium_monthly',
};

export const planIdSwitch: Record<PlanId<'yearly' | 'monthly'>, PlanId<'yearly' | 'monthly'>> = {
  basic_yearly: 'basic_monthly',
  basic_monthly: 'basic_yearly',
  basic_monthly_01: 'basic_yearly_01',
  basic_yearly_01: 'basic_monthly_01',
  standard_monthly: 'standard_yearly',
  standard_yearly: 'standard_monthly',
  premium_monthly: 'premium_yearly',
  premium_yearly: 'premium_monthly',
  standard_monthly_01: 'standard_yearly_01',
  standard_yearly_01: 'standard_monthly_01',
};

export const pricingPlanSwitch: Record<LegacyPricingPlans<'annual' | 'monthly'>, LegacyPricingPlans<'annual' | 'monthly'>> = {
  basic_plan: 'basic_plan_annual',
  basic_plan_annual: 'basic_plan',
  standard_plan: 'standard_plan_annual',
  standard_plan_annual: 'standard_plan',
  premium_plan: 'premium_plan_annual',
  premium_plan_annual: 'premium_plan',
};

export const pricePeriodByBillingPeriod: Record<PlanApiData['billingPeriod'], PricePeriodicity> = {
  monthly: 'month',
  yearly: 'year',
  semi_yearly: 'semi_year',
  quarterly: 'quarter',
};

export const monthlyMultiplierByRecurrence: Record<PricePeriodicity, number> = {
  month: 1,
  year: 12,
  semi_year: 6,
  quarter: 3,
};
