import { InjectionToken, Injector } from '@angular/core';
import { JSONValue } from '@growthbook/growthbook';
import { environment } from 'src/environments/environment';
import { AuthCompany, ReportType, WebAppTrackingMode } from 'src/models';
import { getDisableInvite, isAlphaCustomer, isBeta, isBetaCustomer, isExecutive, isTimeDoctor, isTimeDoctorSales } from '../app/app.constants';
import { hasAnyExperimentalReportAccess, hasExperimentalReportAccess, reportDefs } from '../pages/experimental-reports/reports';
import { APP_URL } from '../services/app.constants';
import { BrandingService } from '../services/branding.service';
import { GrowthBookFeature, GrowthBookService } from '../services/growthbook/service';
import { NotificationVisitService } from '../services/notification-visit.service';
import { flatten } from '../util/array-helpers';
import { getVisitedPageState } from './localstorage-util';

const brandingService = new BrandingService(APP_URL.toString());

export type MenuCondition = (company: AuthCompany, injector: Injector) => boolean;

export interface MenuBadge {
  text?: string;
  icon?: string;
  condition?: MenuCondition;
  iconTooltip?: string;
  tooltip?: string;
}

export type MenuItem = {
  name: string;
  condition?: MenuCondition;
  routerLink: string;
  fragment?: string;
  badge?: MenuBadge | MenuBadge[];
} | {
  name: string;
  condition?: MenuCondition;
  href: string;
  badge?: MenuBadge | MenuBadge[];
};

export type MenuLabel = {
  name: string;
  condition?: MenuCondition;
};

export interface MenuParent {
  name: string;
  condition?: MenuCondition;
  children: (MenuItem | MenuLabel)[];
  badge?: MenuBadge | MenuBadge[];
  group?: string;
  showHeaderBadgeIcon?: MenuCondition;
}

export interface MenuDef {
  items: (MenuItem | MenuParent)[];
  sideNavHeaderBadge?: MenuCondition;
}

export const MENU_DEF = new InjectionToken<MenuDef>('Menu Definition');

export const isWhiteLabel = () => !brandingService.isDefault;
export const disableDownload = () => brandingService.downloadDisabled();

const SOFT_CONDITION_TOKEN = new InjectionToken<boolean>('Soft condition token', {
  providedIn: 'root',
  factory: () => false,
});

export function createSoftConditionInjector(injector: Injector, value = true) {
  return Injector.create({
    name: 'Soft condition injector',
    parent: injector,
    providers: [
      { provide: SOFT_CONDITION_TOKEN, useValue: value },
    ],
  });
}

/**
 * Sometimes the requirements for a condition may not be initialized yet when evaluating a condition.
 * For example, feature flags may not be loaded yet when showing some menus.
 * It ok to not show the menu until feature flags are loaded completely.
 * However, if the user is already in that page,  our routing will kick the user out of the page in this case.
 * This is not desirable. Thus, we have a concept of soft conditions to solve this.
 * Soft conditions will always evaluate to true in soft condition contexts.
 * Route guards are an example of soft condition contexts.
 * @param condition The condition to evaluate if we are not in soft condition context
 * @returns Always true in soft condition context, result of the `condition` otherwise
 */
export function softCondition(condition: MenuCondition) {
  return ((company, injector) => {
    const isSoftCondition = injector.get(SOFT_CONDITION_TOKEN, false);
    return isSoftCondition || (!condition || condition(company, injector));
  }) as MenuCondition;
}

/**
 * Used for defining a feature-flag menu condition
 * @example
 * ```
 *   condition: hasFeatureFlag('billing-page', 'new')
 * ```
 */
export function hasFeatureFlag<T extends JSONValue = any>(flag: GrowthBookFeature, value: T, defaultValue?: T): MenuCondition {
  return (company, injector) => {
    const isSoftCondition = injector.get(SOFT_CONDITION_TOKEN, false);
    const gb = injector.get(GrowthBookService);

    // When GB is not initialized, return true in soft condition context
    if (!gb.initialized.value && isSoftCondition) return true;

    return !!gb.getFeatureValue<any>(flag, defaultValue) === value;
  };
}

/**
 * Used for defining a feature-flag menu condition with boolean values
 * @example
 * ```
 *   condition: isFeatureFlagOn('user-created-task')
 * ```
 */
export function isFeatureFlagOn(flag: GrowthBookFeature, defaultValue?: boolean): MenuCondition {
  return hasFeatureFlag(flag, true, defaultValue);
}

export function isRegularUser(company: AuthCompany) {
  return company.role === 'user';
}

export function isGuest(company: AuthCompany) {
  return company.role === 'guest';
}

export function hasMandatoryCall(company: AuthCompany) {
  const hasToBookCall = company?.companySettings?.custom?.['hasMandatoryCall'] ?? false;
  return company?.subscription?.status === 'trial' && hasToBookCall;
}

export function isManager(company: AuthCompany | undefined | null) {
  return ['manager', 'admin', 'owner'].includes(company?.role ?? '');
}

export function isAdmin(company: AuthCompany) {
  return ['admin', 'owner'].includes(company.role);
}

export function isOwner(company: AuthCompany) {
  return company.role === 'owner';
}

export function isTaskBased(company: AuthCompany) {
  return company.companySettings.tasksMode !== 'off' && company.companySettings.trackingMode !== 'silent';
}

export function isSilent(company: AuthCompany) {
  return company.companySettings.trackingMode === 'silent';
}

export function isInteractive(company: AuthCompany) {
  return !isSilent(company);
}

export function isTimedoctorCompany(company: AuthCompany) {
  return isTimeDoctor(company.id);
}

export function isTimedoctorSalesCompany(company: AuthCompany) {
  return isTimeDoctorSales(company.id);
}

export function isQueryExecutive(company: AuthCompany) {
  return isExecutive;
}

export function isCompanyBigForExecutive(company: AuthCompany) {
  return company?.userCount >= 20;
}

export function isAlphaCompany(company: AuthCompany) {
  return isAlphaCustomer(company.id) || environment.hmr;
}

export function isBetaCompany(company: AuthCompany) {
  return isBetaCustomer(company.id) || isBeta;
}

export function isNotBasicPlanWithNoBillingChangeDate(company: AuthCompany) {
  // we only need to apply pricing plan restriction if billingChangeDate exists
  // to exclude existing customers
  const applyRestrictions = !!company?.billingChangeDate;
  return !applyRestrictions || !company?.pricingPlan?.includes('basic');
}

export function isPricingPlanPremium(company: AuthCompany) {
    return company?.pricingPlan?.includes('premium');
}
export function connectivityEnabled(company: AuthCompany) {
  return company.companySettings.trackConnectivity;
}

export function isDevelopment() {
  return !environment.production;
}

export function guestHasAccessTo(report: ReportType) {
  return (company: AuthCompany) => company.userSettings.custom.allowedReports?.[report];
}

export function hasScreencasts(company: AuthCompany) {
  const ssUser = parseFloat(company?.userSettings.screenshots.toString());
  const isClientWithScreencastsReport = company.role === 'guest' && company?.userSettings?.custom?.allowedReports?.screencasts;
  const screencastsEnabledForUser = ssUser > 0 || company?.userSettings.videos === 'on' || isClientWithScreencastsReport;
  const screencastsEnabledForCompany = company?.companySettings.screencastsFeature !== false;
  const isScreencastViewingAllowedForRegularUsers = isManager(company) || !disableRegularUsersScreencasts(company);
  return screencastsEnabledForCompany
    && (company?.hasManagedScreencasts || screencastsEnabledForUser)
    && isScreencastViewingAllowedForRegularUsers
    && (screencastsEnabledForUser || hasScreencastsAccessLevel(company));
}

export function hasScreencastsAccessLevel(company: AuthCompany) {
  const whoCanAccessScreenshots = company?.companySettings?.whoCanAccessScreenshots;

  if (company?.role === 'user' || company?.role === 'owner' || !whoCanAccessScreenshots) {
    return true;
  }

  return whoCanAccessScreenshots.includes(company.role);
}

function hasManagedApprovals(company: AuthCompany) {
  return isManager(company) && company.hasManagedApprovals;
}

export function regularUserNeedsApproval(company: AuthCompany) {
  if (isFeatureFlagOn('leave-tracking') && isFeatureFlagOn('leave-approval')) {
    return company?.role === 'user';
  }
  return isNotBasicPlanWithNoBillingChangeDate(company) && company?.role === 'user' && company?.userSettings?.canEditTime === 'needApproval';
}

export function and(...conditions: MenuCondition[]): MenuCondition {
  return (company: AuthCompany, injector: Injector) => {
    return conditions.every(x => x == null || x(company, injector));
  };
}

export function or(...conditions: MenuCondition[]): MenuCondition {
  return (company: AuthCompany, injector: Injector) => {
    return conditions.some(x => x?.(company, injector));
  };
}

export function not(condition: MenuCondition): MenuCondition {
  return (company: AuthCompany, injector: Injector) => {
    return !condition(company, injector);
  };
}

export const schedulesEnabled = (company: AuthCompany) => company.companySettings.workScheduleFeature;

export const hasEmailSubscription = (company: AuthCompany) => company?.userSettings.emailSubCount > 0;

export const payrollEnabled = and(
  isInteractive,
  (company: AuthCompany) => company.companySettings.payrollFeature && (isOwner(company) || company.userSettings.payrollAccess),
);

export const disableReportAccess = (company: AuthCompany) => company?.userSettings?.disableReportsAccess;

export const billingAccess = and(
  not(isGuest),
  or(isOwner, (company: AuthCompany) => company.userSettings.billingAccess),
  () => !brandingService.billingDisabled(),
);

export const isTrialer = (company: AuthCompany) => {
  return company?.subscription?.status === 'trial';
};

export const visitedPage = (page: string) => (getVisitedPageState(page) === 'true');
export const visitedNotifications = (notif: string) => {
  return (company, injector) => {
    const notifService = injector.get(NotificationVisitService);
    return notifService?.visitedNotification?.(notif) ?? false;
  };
};

export const shouldShowPremiumTrialBadge: MenuCondition = and(
  isTrialer,
  billingAccess,
  (company, injector) => !visitedNotifications('billingImp')(company, injector),
);

export const webAppTrackingIs = (value: WebAppTrackingMode) => (x => x.companySettings?.webAndAppTracking === value) as MenuCondition;
export const hasWebAppTracking = not(webAppTrackingIs(WebAppTrackingMode.Off));

export const editTimeEnabled = and(not(isGuest), ((company: AuthCompany) => company.userSettings.canEditTime !== 'no'));

export const productivityEnabledForManager = and(x => x.companySettings?.allowManagerTagCategories, hasWebAppTracking);
export const projectsEnabledForManager: MenuCondition = x => x.companySettings?.allowManagerProjectsTasks;
export const inviteEnabledForManager: MenuCondition = x => x.companySettings?.allowManagerInviteUsers;
export const isManualProvisioningEnabled: MenuCondition = x => !x.companySettings?.disableManualUserProvisioning;

export const allowUsersActivitySummaryReport = (company: AuthCompany) => company.companySettings?.allowUsersActivitySummaryReport;
export const disableRegularUsersScreencasts = (
  company: AuthCompany | undefined | null,
) => company?.companySettings?.disableRegularUsersScreencasts ?? false;

const isBasicPlan: MenuCondition = x => x?.pricingPlan?.includes('basic');
export const isAddonsPageEnabled: MenuCondition = and(
  isFeatureFlagOn('addons-page'),
  billingAccess,
  isBasicPlan,
);

export const hasNotificationsAccess: MenuCondition = or(not(isBasicPlan), isFeatureFlagOn('email-notifications-upsell'));

export const experimentalReportMenuItems: MenuItem[] = reportDefs.map(reportDef => ({
  name: `experimental.reports.${reportDef.id}`,
  condition: hasExperimentalReportAccess(reportDef),
  routerLink: '/experimental-reports',
  fragment: reportDef.id,
  badge: reportDef.badge,
}));

export const managerSettings: (MenuItem | MenuParent)[] = [
  {
    name: 'header.settings',
    condition: and(isManager, not(isAdmin), or(
      hasNotificationsAccess,
      productivityEnabledForManager,
      projectsEnabledForManager,
      billingAccess,
      schedulesEnabled,
    )),
    children: [
      { name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess },
      { name: 'header.settingsTasks', routerLink: '/projects-and-tasks', condition: and(isTaskBased, projectsEnabledForManager) },
      {
        name: 'header.settingsNotifications', routerLink: '/notifications', condition: hasNotificationsAccess, badge: [
          { text: 'common.new', condition: and(isFeatureFlagOn('email-notifications-upsell'), () => !visitedPage('notifications')) },
        ],
      },
      {
        name: 'header.settingsProductivity', routerLink: '/productivity-ratings', condition: and(hasWebAppTracking, isFeatureFlagOn('productivity-ratings:enabled')),
        badge: [
          { text: 'common.improved', condition: and(productivityEnabledForManager, isFeatureFlagOn('menu-productivity-ratings-improved-badge')) },
          { icon: 'announcement', iconTooltip: 'errors.accessContactAdmin', condition: not(productivityEnabledForManager) },
        ],
      },
      { name: 'header.settingsSchedules', routerLink: '/schedules', condition: and(isNotBasicPlanWithNoBillingChangeDate, schedulesEnabled) },
    ],
  },
];

export const userSettings: (MenuItem | MenuParent)[] = [
  {
    name: 'header.settings',
    condition: and(isRegularUser, or(billingAccess, schedulesEnabled)),
    children: [
      { name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess },
      { name: 'header.settingsSchedules', routerLink: '/schedules', condition: and(isNotBasicPlanWithNoBillingChangeDate, schedulesEnabled) },
      {
        name: 'header.settingsNotifications', routerLink: '/notifications',
        condition: and(isFeatureFlagOn('email-recipients'), hasEmailSubscription, isRegularUser, hasNotificationsAccess, or(billingAccess, schedulesEnabled)),
      },
    ],
  },
];

export const guestReports: MenuItem[] = [
  {
    name: 'reports.projectsAndTasks',
    routerLink: '/projects-report',
    condition: and(isTaskBased, isGuest, guestHasAccessTo('projectsAndTasks')),
  },
  {
    name: 'reports.screencasts',
    routerLink: '/screencasts',
    condition: and(hasScreencasts, isGuest, guestHasAccessTo('screencasts')),
  },
  {
    name: 'reports.activitySummary',
    routerLink: '/activity-summary',
    condition: and(isGuest, guestHasAccessTo('activitySummary')),
  },
  {
    name: 'reports.hoursTracked',
    routerLink: '/hours-tracked',
    condition: and(isGuest, guestHasAccessTo('hoursTracked')),
  },
  {
    name: 'reports.webAndAppUsage',
    routerLink: '/web-app-usage',
    condition: and(isGuest, guestHasAccessTo('webAndAppUsage'), hasWebAppTracking),
  },
  {
    name: 'reports.connectivity',
    routerLink: '/internet-report',
    condition: and(isGuest, guestHasAccessTo('connectivity')),
  },
  {
    name: 'reports.timeline',
    routerLink: '/timeline',
    condition: and(isGuest, guestHasAccessTo('timeline')),
  },
  {
    name: 'reports.attendance',
    routerLink: '/attendance',
    condition: and(isGuest, guestHasAccessTo('attendance')),
  },
];


const menuDef: MenuDef = {
  items: [
    {
      name: 'header.dashboard',
      routerLink: '/dashboard-individual',
      condition: and(isRegularUser, not(disableReportAccess)),
    },
    {
      name: 'header.dashboard',
      children: [
        { name: 'header.executive', routerLink: '/dashboard-executive', condition: and(isAdmin, isFeatureFlagOn('executive-dashboard'), isCompanyBigForExecutive) },
        { name: 'header.team', routerLink: '/dashboard' },
        { name: 'header.user', routerLink: '/dashboard-individual' },
      ],
      condition: and(isManager, not(disableReportAccess)),
    },
    {
      name: 'header.reports',
      children: [
        { name: 'reports.benchmarking', routerLink: '/benchmarks', condition: and(isManager, isFeatureFlagOn('benchmarking:painted-door')), badge: { text: 'common.new' } },
        { name: 'reports.activitySummary', routerLink: '/activity-summary', condition: and(hasWebAppTracking, or(isManager, allowUsersActivitySummaryReport)) },
        { name: 'reports.unusualActivity', routerLink: '/unusual-activity', condition: and(isManager, or(isFeatureFlagOn('unusual-activity-report'), isFeatureFlagOn('unusual-activity-upsell'))) },
        { name: 'reports.attendance', routerLink: '/attendance', condition: and(isNotBasicPlanWithNoBillingChangeDate, schedulesEnabled) },
        { name: 'reports.hoursTracked', routerLink: '/hours-tracked' },
        { name: 'reports.projectsAndTasks', routerLink: '/projects-report', condition: isTaskBased },
        { name: 'reports.timeline', routerLink: '/timeline' },
        { name: 'reports.webAndAppUsage', routerLink: '/web-app-usage', condition: or(hasWebAppTracking, isFeatureFlagOn('web-and-app-usage:upsell')), badge: { text: 'common.new', condition: and(isFeatureFlagOn('web-and-app-usage:upsell'), () => !visitedPage('web-app-usage')) } },
        { name: 'reports.connectivity', routerLink: '/internet-report', condition: and(connectivityEnabled, isPricingPlanPremium) },
        { name: 'reports.csv', routerLink: '/csv', condition: isManager },
        {
          name: 'reports.experimental',
          condition: hasAnyExperimentalReportAccess,
          badge: { text: 'common.new' },
        },
        ...experimentalReportMenuItems,
      ],
      condition: and(not(isGuest), not(disableReportAccess)),
    },
    {
      name: 'reports.screencasts',
      routerLink: '/screencasts',
      condition: and(hasScreencasts, not(isGuest), not(disableReportAccess), or(isManager, not(disableRegularUsersScreencasts))),
    },
    ...guestReports,
    {
      name: 'header.editTime',
      routerLink: '/edit-time',
      condition: and(editTimeEnabled, not(disableReportAccess)),
    },
    {
      name: 'header.approvals',
      routerLink: '/approvals',
      condition: and(isInteractive, or(regularUserNeedsApproval, hasManagedApprovals), not(disableReportAccess)),
    },
    ...managerSettings,
    ...userSettings,
    {
      name: 'header.settings',
      condition: and(isAdmin),
      children: [
        {
          name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess,
          badge: [
            { text: 'sidebar.widget.premiumTrial', condition: shouldShowPremiumTrialBadge },
            { text: 'common.improved', condition: and(isAddonsPageEnabled, (company) => !visitedPage('addons')) }, // New badge condition with different text
          ],
        },
        { name: 'header.settingsTasks', routerLink: '/projects-and-tasks', condition: isTaskBased },
        { name: 'header.settingsProductivity', routerLink: '/productivity-ratings', condition: and(hasWebAppTracking, isFeatureFlagOn('productivity-ratings:enabled')), badge: { text: 'common.improved', condition: isFeatureFlagOn('menu-productivity-ratings-improved-badge') } },
        { name: 'header.settingsUsers', routerLink: `/manage-users` },
        { name: 'header.settingsGroups', routerLink: '/manage-user-groups' },
        {
          name: 'header.settingsNotifications', routerLink: '/notifications', condition: and(hasNotificationsAccess, not(disableReportAccess)), badge: [
            { text: 'common.new', condition: and(isFeatureFlagOn('email-notifications-upsell'), () => !visitedPage('notifications')) },
          ],
        },
        { name: 'header.settingsIntegrations', routerLink: '/integrations', condition: isInteractive },
        { name: 'header.settingsCompany', routerLink: '/company/edit' },
        { name: 'header.settingsSchedules', routerLink: '/schedules', condition: and(isNotBasicPlanWithNoBillingChangeDate, schedulesEnabled) },
        { name: 'header.breaks', routerLink: '/breaks', condition: and(not(isBasicPlan), isInteractive, isFeatureFlagOn('breaks', false)), badge: { text: 'common.improved', condition: isFeatureFlagOn('break-limit-setting') } },
      ],
      showHeaderBadgeIcon: or(
        shouldShowPremiumTrialBadge,
        and(isAddonsPageEnabled, (company) => !visitedPage('addons')),
      ),
    },
    { name: 'header.settingsNotifications', routerLink: '/notifications', condition: and(isFeatureFlagOn('email-recipients'), hasEmailSubscription, isRegularUser, hasNotificationsAccess, not(billingAccess), not(schedulesEnabled)) },
    {
      name: 'header.payroll', routerLink: '/payroll', condition: or(and(isFeatureFlagOn('payroll:upsell'), isManager), and(payrollEnabled, not(isGuest), not(disableReportAccess))),
      badge: [
        { text: 'common.improved', condition: and(not(isFeatureFlagOn('payroll:upsell')), isFeatureFlagOn('integration-deel'), () => !visitedPage('payroll')) },
        { text: 'common.new', condition: and(isFeatureFlagOn('payroll:upsell'), () => !visitedPage('payroll')) },
      ],
    },
    { name: 'header.invite', routerLink: '/invite', condition: and(or(isAdmin, and(isManager, inviteEnabledForManager)), isManualProvisioningEnabled, isInteractive, () => !getDisableInvite()) },
    { name: 'header.addManagers', routerLink: '/add-managers', condition: and(isAdmin, isSilent, isManualProvisioningEnabled) },
    { name: 'header.download', routerLink: '/downloads', condition: and(not(isGuest), or(not(disableDownload), isOwner)) },
  ],
  sideNavHeaderBadge: or(
    shouldShowPremiumTrialBadge,
    and(isAddonsPageEnabled, (company) => !visitedPage('addons')),
  ),
};


// Require condition of both parent and children (and operator) in nested scenario
const flatMenu = flatten(menuDef.items.map(x => ('children' in x) ?
  x.children.map(c => ({ ...c, condition: and(c.condition, x.condition) })) : x));

// Require condition of atleast one of alternate routes (or operator) in parallel scenario
const conditionMap = new Map<string, MenuCondition>();
for (const cnd of flatMenu) {
  if ('routerLink' in cnd) {
    const existingCondition = conditionMap.get(cnd.routerLink);
    const newCondition = or(existingCondition, cnd.condition);
    conditionMap.set(cnd.routerLink, newCondition);
  }
}

export function isInMenu(url: string) {
  return !!conditionMap.get(url);
}

/**
 * Determines if a page is accessible by checking the conitions in the menu definitions
 * @param company Company retrieved from auth request
 * @param injector Angular injector service
 * @param url The url starting with `/`
 */
export function isMenuAllowed(company: AuthCompany, injector: Injector, url: string) {
  const condition = conditionMap.get(url);
  if (!condition) return false;
  return !!condition(company, createSoftConditionInjector(injector));
}

export const menuProvider = {
  provide: MENU_DEF,
  useFactory: () => menuDef,
};
