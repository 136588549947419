export interface OnboardingWizardConfig {
  companySize?: string;
  inviteCount?: number;
  currentStep?: string;
}

export class ToggleSidebarCollapsed {
  static type = '[Onboarding] Toggle Sidebar Collapsed';

  constructor(public collapsed: boolean) { }
}

export class CompleteWelcomeStep {
  static type = '[Onboarding] Complete Welcome Step';

  constructor(public stepId: string) { }
}

export class DismissWelcomeStep {
  static type = '[Onboarding] Dismiss Welcome Step';

  constructor(public stepId: string) { }
}

export class DismissWelcomeBanner {
  static type = '[Onboarding] Dismiss Welcome Banner';
}

export class SetOnboardingWizardConfig {
  static type = '[Onboarding] Set Onboarding Wizard Config';

  constructor(public config: OnboardingWizardConfig) { }
}

export class ResetOnboardingWizardConfig {
  static type = '[Onboarding] Reset Onboarding Wizard Config';
}

export class TrackOnboardingProgress {
  static type = '[Onboarding] Track Onboarding Progress';
}

export class SetGettingStartedScreenExperimentVariant {
  static type = '[Onboarding] Set Onboarding Variant';

  constructor(public variantId: string) { }
}
