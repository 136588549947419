import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, mergeMap, Observable, of, retry, switchMap, throwError, timer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BillingError } from 'src/app/pages/new-billing/models/billing-error';
import { InvoiceModel, InvoiceModelImp } from 'src/app/pages/new-billing/models/invoice.model';
import { SubscriptionModelImp } from 'src/app/pages/new-billing/models/subscription.model';
import { UpdateBillingDetails } from 'src/app/pages/new-billing/store/actions';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import {
  BillingApiData,
  BillingDetailsApiData,
  BillingDetailsApiResponse,
  CancelAccountData,
  CancelAccountPayload,
  DiscountApiData,
  DiscountApiResponse,
  GenericApiResponse,
  InvoicePreviewApiResponse,
  InvoicePreviewData,
  ModifySubscriptionPayload,
  PlanApiData,
  PlanId,
  PlansApiResponse,
  ReceiptApiResponse,
  ReceiptDetailsApiResponse,
  SwitchBillingPeriodApiResponse,
  TransactionApiResponse,
  UpdateCustomerBody
} from 'src/app/pages/new-billing/types/api.types';
import { BillingApiService } from 'src/app/services/billing/billing-api.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AppState } from 'src/app/store/state';
import { HttpRequestOptions } from '../api.service';


export type CreateTransactionOpts = {
  planId?: PlanId;
  type?: TransactionIdTypes;
  discountId?: string;
  cache?: CacheOption;
};

export type TransactionIdTypes = 'checkout' | 'updatePaymentDetails';

export type PollingOptions = {
  /** The base delay in milliseconds for the exponential polling. Defaults to 500ms */
  pollingBaseDelayMs?: number;

  /** The number of times to retry the exponential polling. Defaults to 5 */
  pollingRetryCount?: number;
};

export type CacheOption = 'no-cache' | 'store';

const DEFAULT_POLLING_BASE_DELAY_MS = 500;
const DEFAULT_POLLING_RETRY_COUNT = 5;

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private _gracePeriodEndDate: string | null = null;
  private _discounts: DiscountApiData[] = [];

  private cache: { receipts: ReceiptApiResponse | null } = {
    receipts: null,
  };

  constructor(
    private billingApi: BillingApiService,
    private store: Store,
  ) { }

  public getBillingData(cache: CacheOption = 'store'): Observable<BillingApiData> {
    const discountToFind = this.store.selectSnapshot(AuthSelectors.homeCta);

    return this.getBillingDetails(cache).pipe(
      switchMap(billingData => combineLatest([this.getPlans(), this.getDiscounts()]).pipe(
        map(([plans, discounts]) => {
          return {
            billingDetails: billingData,
            plans,
            discounts,
            discountCode: discountToFind,
          };
        }),
      )),
    );
  }

  public getBillingDetails(cache: CacheOption = 'store'): Observable<BillingDetailsApiData> {
    const storedBillingDetails = this.store.selectSnapshot(NewBillingState.updatedBillingDetails);
    if (storedBillingDetails && cache === 'store') return of(storedBillingDetails);

    return this.billingApi.get<BillingDetailsApiResponse>('customer/billing-details').pipe(
      tap((billingDetails) => this.store.dispatch(new UpdateBillingDetails(billingDetails.data))),
      map(response => response.data),
    );
  }

  /**
   * Returns the number of grace period days before a subscription expires.
   * Grace period is the time between the subscription end date and the actual expiration date.
   * If the provider is not 'paddle' or if there is no provider, 0 is returned.
   *
   * @returns An observable emitting the number of grace period days.
   */
  public getGracePeriodDays(optionalGracePeriodEndDate?: string): Observable<number> {
    if (optionalGracePeriodEndDate) this._gracePeriodEndDate = optionalGracePeriodEndDate;
    if (this._gracePeriodEndDate) return of(SubscriptionModelImp.calculateGracePeriod(this._gracePeriodEndDate));
    const provider = this.store.selectSnapshot(AuthSelectors.company)?.subscription?.provider;

    if (provider !== 'paddle') return of(0);

    return this.getBillingDetails().pipe(
      tap((billingDetails) => this.store.dispatch(new UpdateBillingDetails(billingDetails))),
      map(({ gracePeriodEndDate }) => SubscriptionModelImp.calculateGracePeriod(gracePeriodEndDate)),
      catchError(() => {
        return of(0);
      }),
    );
  }

  public getPlans(): Observable<PlanApiData[]> {
    const options: HttpRequestOptions = {
      params: {
        billingSystem: 'paddle',
      },
    };

    return this.billingApi.get<PlansApiResponse>('plan', options).pipe(
      map(response => response.data),
      catchError(() => of([])),
    );
  }

  public getInvoicesData(): Observable<InvoiceModel[]> {
    return this.getReceipts().pipe(
      map(receiptsResponse => {
        return receiptsResponse.data.map(receipt => new InvoiceModelImp(receipt))
          .filter(invoice => invoice.status !== 'unknown');
      }),
      catchError(() => of([])),
    );
  }

  /**
   * @throws BillingError when the request fails.
   */
  public getUpcomingInvoicePreview(): Observable<InvoicePreviewData> {
    return this.billingApi.get<InvoicePreviewApiResponse>('customer/invoice-preview').pipe(
      map(response => {
        const { lineItems, ...restData } = response.data;
        const tax = +restData.totals.tax;
        const items: InvoicePreviewData['items'] = lineItems.map(lineItem => ({
          product: `${lineItem.product.name} ${lineItem.product.interval} plan`,
          quantity: lineItem.quantity,
          unitPrice: +lineItem.unitTotals.subtotal,
          amount: +lineItem.totals.subtotal,
        }));

        return {
          items,
          tax,
          ...restData,
        };
      }),
      catchError((errResponse) => throwError(() => new BillingError({
        ...errResponse,
        errorCode: errResponse.error || 'unknown_error',
      }))),
    );
  }


  public getReceiptDetails(transactionId: string): Observable<ReceiptDetailsApiResponse> {
    return this.billingApi.get<ReceiptDetailsApiResponse>(`customer/receipt-details`, {
      params: {
        transactionId,
      },
    });
  }

  public getTransactionId({
    planId,
    discountId,
    cache,
    ...restOpts
  }: CreateTransactionOpts = { cache: 'store' }): Observable<string> {
    const headers = cache === 'no-cache' ? { 'Cache-Control': 'no-cache' } : undefined;

    return this.billingApi.get<TransactionApiResponse>('customer/create-transaction', {
      params: {
        ...(planId && { planId }),
        ...(discountId && { discountId }),
        ...restOpts,
      },
      ...(headers ? { headers } : {}),
    })
      .pipe(
        map(response => response.data.transactionId),
      );
  }

  public pollBillingDetailsUntilConditionMet(
    conditionFn: (billingData: BillingDetailsApiData) => boolean,
    options: PollingOptions = {},
  ): Observable<BillingDetailsApiData> {
    const {
      pollingBaseDelayMs = DEFAULT_POLLING_BASE_DELAY_MS,
      pollingRetryCount = DEFAULT_POLLING_RETRY_COUNT,
    } = options;

    return this.getBillingDetails('no-cache').pipe(
      mergeMap((val) => (conditionFn(val) ? of(val) : throwError(() => 'Condition not met'))),
      retry({
        count: pollingRetryCount,
        delay: (_err, retryCount) => {
          const delayMs = pollingBaseDelayMs * Math.pow(2, retryCount);
          return timer(delayMs);
        },
      }),
    );
  }

  public updateCustomerEmail(email: string): Observable<GenericApiResponse> {
    return this.billingApi.put<GenericApiResponse, UpdateCustomerBody>('customer', { email });
  }

  /**
   * Sends a cancellation request to the billing API.
   *
   * @param cancelData contains the data set in the cancellation form. This is completed with the user data and the
   *   company data.
   *
   * @throws BillingError with the `errorCode` set to `cancel_request_error` if the cancellation request fails.
   */
  public cancelSubscription(cancelData: CancelAccountData): Observable<GenericApiResponse> {
    const company = this.store.selectSnapshot((state: AppState) => state.auth.company);
    const user = this.store.selectSnapshot((state: AppState) => state.auth.user);

    return this.billingApi.put<GenericApiResponse, CancelAccountPayload>('customer/cancel-subscription', {
      ...cancelData,
      userId: user.id,
      email: user.email,
      tdUserId: user.id,
      companyType: company.companySettings.trackingMode,
    }).pipe(
      catchError(error => throwError(() => new BillingError({
        ...error,
        errorCode: 'cancel_request_error',
      }))),
    );
  }

  public modifySubscriptionPlan(data: ModifySubscriptionPayload): Observable<GenericApiResponse> {
    return this.billingApi.patch<GenericApiResponse, ModifySubscriptionPayload>('customer', data)
      .pipe(
        catchError((err) => throwError(() => new BillingError({
          ...err,
          errorCode: err.error,
        }))),
      );
  }

  public getDiscounts(): Observable<DiscountApiData[]> {
    if (this._discounts.length) {
      return of(this._discounts);
    }

    return this.billingApi.get<DiscountApiResponse>('discount', {
      params: { billingSystem: 'paddle' },
    }).pipe(
      tap((response) => this._discounts = response.data),
      map((response) => {
        return response.data;
      }),
      catchError(() => of([])),
    );
  }

  public getReceipts(refreshCache: boolean = false): Observable<ReceiptApiResponse> {
    if (this.cache.receipts && !refreshCache) {
      return of(this.cache.receipts);
    }

    return this.billingApi.get<ReceiptApiResponse>('customer/receipts').pipe(
      map((response) => {
        this.cache.receipts = response;
        return response;
      }),
      catchError(() => of({ data: [] } as ReceiptApiResponse)),
    );
  }

  public getPastDueInvoices(): Observable<InvoiceModel[]> {
    return this.getInvoicesData().pipe(
      map(invoices => invoices.filter(invoice => invoice.status === 'past_due')),
      catchError(() => of([])),
    );
  }

  public retryFailedPayment(transactionId: string): Observable<any> {
    return this.billingApi.post<any>('customer/payment-retry', null, { transactionId });
  }

  public getSwitchBillingPeriod(planId: PlanId) {
    return this.billingApi.get<SwitchBillingPeriodApiResponse>('customer/switch-billing-period', { params: { planId } }).pipe(
      map(response => response.data),
      catchError((err) => throwError(() => new BillingError({
        ...err,
        errorCode: err.error,
      }))),
    );
  }
}
