import { Buffer } from 'buffer';
import semver from 'semver';

const baseHostname = 'https://download.timedoctor.com';

export const getInteractiveInstallerUrl = (platform: string, appName: string, version: string, msi: boolean = false): string => {
    const baseUrl = `${baseHostname}/${version}`;
    if (platform === 'win') {
        if (semver.gte(version, '3.15.43') || msi) {
            return `${baseUrl}/windows/interactive/${appName}-setup-${version}-windows.msi`;
        }
        return `${baseUrl}/windows/bitrock/${appName}-setup-${version}-windows.exe`;
    }
    if (platform === 'mac') {
        const installerType = semver.gte(version, '3.14.0') ? 'pkg' : 'dmg';
        return `${baseUrl}/macos/${installerType}/${appName}-${version}-macos.${installerType}`;
    }
    if (platform === 'linux') {
        return `${baseUrl}/linux/ubuntu-18.04/interactive/${appName}-setup-${version}-linux-x86_64.run`;
    }
    return '';
};

const getSilentInstallerPath = (platform: string, version: string): string => {
    if (platform === 'win') {
        return `${version}/windows/silent/sfproc-setup-${version}-windows.msi`;
    }
    if (platform === 'mac') {
        const suffix = semver.lt(version, '3.8.5') ? 'macos-amd64' : 'macos';
        return `${version}/macos/silent/sfproc-${version}-${suffix}.run`;
    }
    if (platform === 'linux') {
        return `${version}/linux/ubuntu-18.04/silent/sfproc-${version}-x86_64.run`;
    }
    return '';
};

const isCloudDeployment = (hostname: string | undefined): boolean => {
    return !hostname || ['app.staff.com', 'app.timedoctor.com', '2.timedoctor.com', '2a.timedoctor.com'].indexOf(hostname) >= 0;
};

const getEncoded = (companyId: string): string => {
    return Buffer.from(companyId, 'base64').toString('hex');
};

const getSilentInstallerName = (platform: string, hostname: string | undefined, companyId: string, version: string): string => {
    if (platform === 'win') {
        const company = getEncoded(companyId);
        const deployment = isCloudDeployment(hostname) ? '' : `_${hostname}_`;
        return `sfproc-${version}-${company}${deployment}.msi`;
    }
    return '';
};

const getDeploymentArgument = (hostname: string | undefined): string => {
    if (isCloudDeployment(hostname)) {
        return '';
    }
    return hostname.endsWith('.timedoctor.com') ? ` --install-host=${hostname}` : ' --install-host=on-premise';
};

export const getSilentInstallCommand = (platform: string, hostname: string | undefined, companyId: string, version: string): string => {
    const installerPath = '/tmp/sfproc';
    const installerUrl = `${baseHostname}/${getSilentInstallerPath(platform, version)}`;
    const installerArguments = `--nox11 -- --company-id=${getEncoded(companyId)}${getDeploymentArgument(hostname)}`;
    const shellCommand = `${installerPath} ${installerArguments} && rm ${installerPath}`;
    if (platform === 'mac') {
        return `curl -o ${installerPath} ${installerUrl} && sudo /bin/zsh ${shellCommand}`;
    }
    if (platform === 'linux') {
        return `wget -O ${installerPath} ${installerUrl} && sudo /bin/bash ${shellCommand}`;
    }
    return '';
};

export const getRenamedWindowsSilentInstallerUrl = (hostname: string | undefined, companyId: string, version: string): string => {
    const filePath = getSilentInstallerPath('win', version);
    const downloadName = getSilentInstallerName('win', hostname, companyId, version);
    return `${baseHostname}/api/download?path=${filePath}&name=${downloadName}`;
};
